<template>
  <a
    :class="{ disabled: !href }"
    :download="link.type === 'downloadEvent'"
    :href="href"
    class="component-event-link"
    @click="onClick"
  >
    <slot />
  </a>
</template>
<script>
import linkMixin from './linkMixin'

export default {
  mixins: [linkMixin]
}
</script>
<style lang="less" scoped>
.component-event-link {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  &.disabled {
    pointer-events: none;
  }
  .text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
</style>
