/* eslint-disable */
import Float from '../components/video-float/Float'
import { placeholders, playStyleTypeMap, showTimeStatusMap, videoStatus } from '../components/video-float/constants'
import {
  MINI_video_loading_success,
  MINI_video_fullscreen_click,
  MINI_video_play_click,
  MINI_video_playcount,
  MINI_video_playduration,
  MINI_video_replay_click,
  MINI_video_startplay,
  MINI_video_unfullscreen_click
} from '../constants/reportEvents'
import { getEleTop, getFirstScreen } from '../utils'
import { isPreload } from '@/utils'

export default {
  props: ['component', 'context', 'download'],
  provide() {
    return {
      compName: this.component.name,
      compId: this.component.id
    }
  },
  data() {
    return {
      video: {},
      player: null,
      isEmpty: false,
      playCardsTimer: null,
      videoStatus: videoStatus.INIT,
      videoHoldStatus: videoStatus.INIT,
      playTime: 0,
      videoRef: null
    }
  },
  watch: {
    playMode() {
      this.reset()
    },
    'content.video': {
      immediate: true,
      // deep: true,
      handler(video, old) {
        if (JSON.stringify(video) === JSON.stringify(old)) {
          return
        }

        this.onContentVideoUpdate(video)
      }
    },
    'floating.showTimeStatus'() {
      this.$nextTick(() => this.playCards())
    },
    'floating.playStyleType'() {
      this.$nextTick(() => this.playCards())
    },
    'floating.floatSetting.imageUrl'() {
      const { playStyleType } = this.component?.data?.content?.floating || {}

      if (+playStyleType !== 3) {
        return
      }

      this.$nextTick(() => this.playCards())
    }
  },
  computed: {
    isEditor() {
      return this.context === 'editor'
    },
    data() {
      return this.component.data || {}
    },
    content() {
      return this.component.data?.content || {}
    },
    emptyVideoStyle() {
      const { name } = this.component || {}
      const placeholder = placeholders[name] || placeholders['plain-video']

      return {
        backgroundImage: placeholder ? `url(${placeholder})` : ''
      }
    },
    //
    playMode() {
      return this.content?.playMode
    },
    floating() {
      return this.content?.floating || {}
    },
    videoCloudSource() {
      return this.content?.video?.videoCloudSource || {}
    },
    manuscriptVideo() {
      return this.content?.video?.manuscriptVideo || {}
    },
    videoDuration() {
      return Math.round(this.videoCloudSource.duration / 1000)
    },
    reportInfo() {
      return {
        id: `v${this.component.id}`,
        item_name: this.component.name,
        item_id: this.component.id,
        item_style: 'video',
        play_type: +this.playMode < 3 ? 0 : 1,
        video_duration: this.videoDuration,
        biz_id: this.videoCloudSource.biz_id || 0,
        avid: this.manuscriptVideo.aid || 0,
        cid: this.manuscriptVideo.cid || 0,
        video_type: this.content?.video?.videoType,
        enter_inline: 0,
        first_screen: Number(getFirstScreen(this.videoRef)),
        top: getEleTop(this.videoRef),
        ignore_impr: this.context === 'editor'
      }
    }
  },
  methods: {
    onContentVideoUpdate(contentVideo) {
      const { videoType, videoCloudSource, manuscriptVideo } = contentVideo || {}
      const video = videoType ? manuscriptVideo : videoCloudSource
      const { aid, url } = video || {}
      const isEmpty = !aid && !url

      this.video = video
      this.isEmpty = isEmpty

      this.$nextTick(() => setTimeout(() => this.onVideoUpdate()))
    },
    onVideoUpdate() {
      if (this.isEmpty && this.player) {
        this.player.destroy()
        this.player = null
        // this.clearCards()
        return
      }

      if (!this.isEmpty) {
        this.reset()
        this.component.data?.floating && this.playCards()
        this.onVideoChange(this.video)
        this.playCards(false)
      }
    },
    onVideoChange(video) {},
    seekVideo(time = 0, isReplay) {
      this.player && this.player.seek(time)
      this.videoStatus = videoStatus.PLAY

      if (isReplay) {
        this.$reportUI(MINI_video_replay_click, { ...this.reportInfo })
      }
    },
    onVideoHold() {
      this.videoHoldStatus = this.videoStatus
      this.player && this.player.pause()
    },
    onVideoContinue() {
      if (this.videoHoldStatus !== videoStatus.PLAY) {
        this.videoHoldStatus = videoStatus.INIT
        return
      }

      this.videoHoldStatus = videoStatus.PLAY
      this.player && this.player.play()
    },
    onMediaSeek({ time = 0 } = {}) {
      if (this.floating.showTimeStatus === showTimeStatusMap.END) {
        return
      }

      this.onFloatChange(time, false)
    },
    onMediaEnded() {
      this.playTime = this.videoDuration
      this.$reportUI(MINI_video_playcount, { ...this.reportInfo })

      const { showTimeStatus = 0 } = this.floating || {}
      // 如果没有结束浮层，则循环播放
      if (!showTimeStatus || showTimeStatus === showTimeStatusMap.PLAY) {
        return this.seekVideo(0)
      }

      this.onFloatChange(100, true)
      this.$emit('ended')
      this.videoStatus = videoStatus.ENDED
    },
    onMediaLoaded() {
      this.$emit('loaded')
      this.coverLoadHandler()
    },
    onMediaTimeChange() {
      this.playTime = this.player.getCurrentTime()
      if (this.floating.showTimeStatus === showTimeStatusMap.END) {
        return
      }

      const time = this.player.getCurrentTime()

      this.onFloatChange(time, false)
    },
    onMediaPlay() {
      this.videoStatus = videoStatus.PLAY

      // 一系列埋点
      // 1. 播放状态切换埋点
      // 2. 视频开始播放埋点
      // 3. 视频资源加载成功埋点 = Min(视频开始播放， 封面图加载)
      if (!this.MINI_video_startplay) {
        this.$reportUI(MINI_video_startplay, { ...this.reportInfo })
        this.MINI_video_startplay = true
        if (!this.MINI_video_loading_success) {
          this.$reportUI(MINI_video_loading_success, {
            ...this.reportInfo,
            report_type: 'video',
            load_time: Date.now() - this.start
          })
          this.MINI_video_loading_success = true
        }
      }
      this.$reportUI(MINI_video_play_click, { ...this.reportInfo, state: 1 })
    },
    onMediaPause() {
      this.videoStatus = videoStatus.PAUSED
      this.$reportUI(MINI_video_play_click, { ...this.reportInfo, state: 0 })
    },
    onFullScreenModeChange({ mode }) {
      // 0 普通模式 2 网页全屏 3 浏览器全屏
      if (mode === 0) {
        this.$reportUI(MINI_video_unfullscreen_click, this.reportInfo)
      } else if (mode === 2 || mode === 3) {
        this.$reportUI(MINI_video_fullscreen_click, this.reportInfo)
      }
    },
    onFloatChange(time, end) {
      this.$refs.float && this.$refs.float.showFloat(time, end)
    },
    init() {
      if (isPreload()) {
        // 预加载状态不加载视频信息
        return
      }
      this.loadSdk()
        .then(() => {
          const { aid, cid, url, cover } = this.video || {}
          if (!aid && !url) {
            return
          }

          const defaultOptions = {
            aid: 0,
            cid: 0,
            danmaku: false,
            hideDanmakuButton: true,
            hideCoverInfo: true,
            noRecommend: true,
            autoplay: +this.playMode < 3,
            autoplayMode: +this.playMode === 2 || this.isEditor ? 'muted' : 'auto',
            element: this.$refs.video,
            callAppMode: false,
            showReturn: true,
            webFull: true
          }

          const aidOptions = {
            ...defaultOptions,
            aid,
            cid,
            readyPoster: cover
          }

          const urlOptions = {
            ...defaultOptions,
            readyPoster: cover,
            readyVideoUrl: url
          }

          const options = aid ? aidOptions : urlOptions
          this.player = new window.BPlayerMobile(options)

          this.player.on('video_media_seek', this.onMediaSeek)
          this.player.on('video_media_ended', this.onMediaEnded)
          this.player.on('video_media_loaded', this.onMediaLoaded)
          this.player.on('video_media_time', this.onMediaTimeChange)
          this.player.on('video_media_play', this.onMediaPlay)
          this.player.on('video_media_pause', this.onMediaPause)
          this.player.on('video_fullscreen_mode_changed', this.onFullScreenModeChange)
        })
        .catch((error) => {
          console.error('error', error)
        })
    },
    reset() {
      this.player && this.player.destroy()
      this.init()
    },
    loadSdk() {
      // CDN 有强缓存，所以建议增加一个 5 分钟更新的 query string
      const url =
        '//s1.hdslb.com/bfs/static/player/main/html5/mbplayer.js' + `?v=${Math.ceil(Date.now() / (5 * 60 * 1000))}`

      if (window.BPlayerMobile) {
        return Promise.resolve()
      }

      return new Promise((resolve, reject) => {
        const a = document.createElement('script')

        a.onload = () => resolve()
        a.onerror = () => reject(new Error(''))

        a.async = true
        a.deffer = true
        a.src = url

        document.head.appendChild(a)
      })
    },
    clearCards() {
      this.$refs.float && this.$refs.float.clearFloat()
    },
    playCards(immediate = true) {
      const queue = []
      const { showTimeStatus, playStyleType } = this.floating || {}

      this.playCardsTimer && clearTimeout(this.playCardsTimer)
      this.clearCards()

      if (this.context !== 'editor' || !showTimeStatus) {
        return
      }

      if (showTimeStatus !== showTimeStatusMap.END) {
        queue.push({ wait: 2000, time: 3, end: false })
      }

      if (showTimeStatus !== showTimeStatusMap.END && playStyleType === playStyleTypeMap.SMALL_BIG) {
        queue.push({ wait: 2000, time: 5, end: false })
      }

      if (showTimeStatus !== showTimeStatusMap.PLAY) {
        queue.push({ wait: 5000, time: 100, end: true })
      }

      const play = () => {
        const top = queue.shift()
        const { time, end, wait } = top || {}

        this.onFloatChange(time, end)

        if (!queue.length) {
          return
        }

        // 多个卡片才需要循环播放
        queue.push(top)
        this.playCardsTimer = setTimeout(() => play(), wait)
      }

      if (immediate) {
        return queue.length && play()
      }

      queue.length && setTimeout(() => play(), 500)
    },
    pageHideHandler() {
      if (!this.MINI_video_playduration) {
        this.MINI_video_playduration = true
        this.$reportUI(MINI_video_playduration, {
          ...this.reportInfo,
          play_time: Number(Math.round(this.playTime).toFixed(1))
        })
      }
    },
    coverLoadHandler() {
      const imageRef = document.querySelector('.mplayer-poster')
      const doReport = () => {
        if (!this.MINI_video_startplay) {
          this.$reportUI(MINI_video_loading_success, {
            ...this.reportInfo,
            report_type: 'img',
            load_time: Date.now() - this.start
          })
          this.MINI_video_loading_success = true
        }
      }
      if (imageRef.complete) {
        doReport()
      }
      imageRef.onload = () => {
        doReport()
      }
    }
  },
  created() {
    window.addEventListener('pagehide', this.pageHideHandler)
    window.addEventListener('beforeunload', this.pageHideHandler)
    this.start = Date.now()
  },
  mounted() {
    // this.reset()
    // this.$nextTick(() => this.playCards(false))
    this.videoRef = this.$refs.video
  },
  beforeDestroy() {
    this.playCardsTimer && clearTimeout(this.playCardsTimer)
    window.removeEventListener('pagehide', this.pageHideHandler)
    window.removeEventListener('beforeunload', this.pageHideHandler)
  },
  components: {
    Float
  }
}
