<template>
  <div v-if="isEmpty" :style="emptyVideoStyle" class="cc-video cc-video-empty" />
  <div v-else class="cc-video">
    <div
      ref="video"
      v-impr="reportInfo.id"
      :impr-data="JSON.stringify(reportInfo)"
      class="bilibiliPlayer cc-video"
    ></div>
  </div>
</template>
<script>
import videoMixin from '../mixins/video'

export default {
  name: 'CcVideo',
  mixins: [videoMixin],
  computed: {
    playMode() {
      return this.data?.content?.playMode
    }
  },
  methods: {
    onVideoChange(video) {
      if (this.context !== 'editor') {
        return
      }

      const { width } = this.data?.layout || {}
      const { width: vWidth, height: vHeight } = video || {}

      const currHeight = Math.ceil((width * vHeight) / vWidth)

      this.data.layout.height = currHeight
      setTimeout(() => this.$emit('block-auto-expand'))
    }
  }
}
</script>
