<template>
  <div v-if="isEditor && data" class="wx-modal">
    <wx-modal-body :data="data" :is-editor="isEditor"></wx-modal-body>
  </div>
  <base-popup v-else v-model="modal.show" close-on-click-overlay>
    <wx-modal-body
      :data="data"
      :is-editor="isEditor"
      :show="modal.show"
      :package-id="packageId"
      :is-force="isForce"
      :report-info="reportInfo"
      @copy="copy"
      @report="conversionUpload"
    ></wx-modal-body>
  </base-popup>
</template>

<script>
import WxModalBody from './WxModalBody'
import { MINI_copy_wx_success, MINI_copy_wx_fail } from '../../constants/reportEvents'
import { serialize } from '../form/utils'
import BasePopup from '../base/BasePopup'

export default {
  name: 'WxModal',
  components: { WxModalBody, BasePopup },
  inject: ['pageQuery', 'pageId'],
  props: {
    isEditor: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    },
    content: {
      type: Object,
      default: null
    }
  },
  data() {
    const isForce = !!window._globalExp?.wx_copy_hit
    return {
      modal: {
        show: false
      },
      isForce,
      reportInfo: { wx_copy_hit: Number(!!isForce) }
    }
  },
  computed: {
    packageId() {
      return this.content?.weixin?.packageId
    }
  },
  created() {
    this.queryRandomWxAccount()
  },
  methods: {
    open() {
      this.queryRandomWxAccount().then(() => {
        if (this.isForce) {
          this.modal.show = true
        } else {
          this.copy(() => {
            this.modal.show = true
          })
        }
      })
    },
    queryRandomWxAccount() {
      if (this.isEditor || !this.packageId || window._globalData?.wxAccount) return Promise.resolve()

      return this.$request.get(`v1/wechat/package/${this.packageId}`).then(({ data = {} }) => {
        window._globalData.wxAccount = data.wechat_account?.wechat_account
        window._globalData.wxType = data.type
        window._globalData.wxAccountId = data.wechat_account?.wechat_account_id
      })
    },

    reportCopySuccess() {
      this.conversionUpload(0)
      this.$reportUI(MINI_copy_wx_success, this.reportInfo)
      this.isForce && this.$toast('复制成功')
    },
    reportCopyFail() {
      this.$reportUI(MINI_copy_wx_fail, this.reportInfo)
    },
    async copy(success = () => {}) {
      if (this.isEditor) return

      const text = window._globalData.wxAccount
      if (navigator && navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(text)
          this.reportCopySuccess()
          success()
        } catch (err) {
          console.log('err', err)
          this.oldCopy(text)
          this.reportCopySuccess()
          success()
        }
      } else {
        this.oldCopy(text) // 非https
        this.reportCopySuccess()
        success()
      }
    },
    oldCopy(val) {
      const textareaEl = document.createElement('textarea')
      textareaEl.value = val
      document.body.appendChild(textareaEl)
      textareaEl.select()
      document.execCommand('copy')
      document.body.removeChild(textareaEl)
    },
    conversionUpload(dataType) {
      const wxConversion = window._globalData.wxConversion || []
      if (wxConversion.includes(dataType)) return

      // 0-复制 1-跳转
      const data = serialize('wechat_package_datas', {
        wechat_package_id: this.packageId,
        wechat_account_id: window._globalData.wxAccountId,
        data_type: dataType
      })

      this.$request.post(`v1/wechat/package/data/page/${this.pageId}${window.location.search}`, data).then(() => {
        if (wxConversion.length === 0) {
          window._globalData.wxConversion = [dataType]
        } else {
          wxConversion.push(dataType)
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.wx-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2000;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
