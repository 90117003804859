<template>
  <div v-if="download && download.action" class="cc-button" @click="onClick">
    <div v-if="largerStyle" class="cc-button-click-larger" :style="largerStyle"></div>
    <div class="text">{{ download.text || text }}</div>
    <download-confirm-modal ref="modal" @download="download.action"></download-confirm-modal>
  </div>
  <LinkComp v-else :link="link" class="cc-button">
    <template #before>
      <div v-if="largerStyle" class="cc-button-click-larger" :style="largerStyle"></div>
    </template>
    <div class="text">{{ text }}</div>
  </LinkComp>
</template>
<script>
import LinkComp from './Link'
import qs from 'qs'
import DownloadConfirmModal from './download/DownloadConfirmModal'
import {
  MINI_auto_download_click,
  MINI_auto_download_show,
  MINI_item_click
} from '../constants/reportEvents'
import { inBiliApp } from '@bilibili/js-bridge'
import { callJSB } from '../utils'

export default {
  name: 'CcButton',
  components: {
    LinkComp,
    DownloadConfirmModal
  },
  inject: ['compName', 'compId'],
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    download: {
      type: Object,
      default: () => null
    },
    isAuto: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      largerStyle: {},
      platform: ''
    }
  },
  computed: {
    link() {
      return this.content?.link || {}
    },
    text() {
      return this.content?.text || ''
    }
  },
  mounted() {
    if (this.isAuto) {
      this.$reportUI(MINI_auto_download_show)
    }
    this.getBtnStyle()
    const ua = window.navigator.userAgent || ''
    const platform =
      ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1
        ? 'android'
        : ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        ? 'ios'
        : 'web'

    this.platform = platform
  },
  methods: {
    getBtnStyle() {
      setTimeout(() => {
        if (window._globalExp?.web_cvr_up && window._globalExp?.button_click_larger?.is_open) {
          this.largerStyle =
            window._globalExp?.button_click_larger?.type === 'scale'
              ? {
                  transform: `scale(${window._globalExp?.button_click_larger.scale})`
                }
              : {
                  left: `${window._globalExp?.button_click_larger.padding}`,
                  right: `${window._globalExp?.button_click_larger.padding}`,
                  top: `${window._globalExp?.button_click_larger.padding}`,
                  bottom: `${window._globalExp?.button_click_larger.padding}`
                }
        }
      }, 1000)
    },
    onCallUpHandler(data) {
      callJSB({
        method: 'cm.callUpApp',
        data: {
          schemaUrl: this.withTrackId(data.scheme)
        },
        callback: (res) => {
          if (res.status !== 1) {
            this.onDownload()
          }
        },
        onNotSupport: () => {
          this.onDownload
        }
      })
    },
    onDownload() {
      // 开关打开不合规，需要弹窗
      const { isDownloadCheckOn, isDownloadIllegal } = window._globalData || {}
      if (isDownloadCheckOn && isDownloadIllegal && this.download?.canTriggerDownloadButton) {
        this.$refs.modal && this.$refs.modal.open()
      } else {
        this.download && this.download.action()
      }
      if (this.download?.isGame) {
        this.$reportUI(MINI_item_click, {
          item_name: this.compName,
          item_id: this.compId,
          item_type: 'button',
          event_type: 'downloadGameEvent'
        })
      }
      if (this.isAuto) {
        this.$reportUI(MINI_auto_download_click)
      }
    },
    onClick() {
      const data = this.link?.behavior
      if (data && data[this.platform]?.scheme && inBiliApp) {
        this.onCallUpHandler(data[this.platform])
      } else {
        this.onDownload()
      }
    },
    withTrackId(url) {
      const search = (window.location.search || '')
        .substr(1)
        .replace('track_id=__TRACKID__', 'track_id=')
      if (!url) {
        return url
      }
      if (search === '') {
        return url
      }

      const query = qs.parse(search) || {}
      const trackId = query.track_id || ''

      if (!trackId) return url

      // 有宏，则将宏无脑替换成实际trackId
      if (url?.includes('__TRACKID__')) {
        return url.replace('__TRACKID__', trackId)
      }

      return url
    }
  }
}
</script>
<style lang="less" scoped>
.cc-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  line-height: 1;
  box-sizing: border-box;
  text-align: center;
  border-style: solid;
  position: relative;
  & .text {
    width: 90%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.cc-button-click-larger {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
