<template>
  <base-popup
    v-model="show"
    position="bottom"
    class="location-auth"
    close-on-click-overlay
    @click-overlay="onClickOverlay"
  >
    <div class="title">
      <img
        class="logo"
        src="https://i0.hdslb.com/bfs/activity-plat/static/20220905/350f34a338378c33628aa40ad0c3ae8e/PCm6LAY9zE.png"
        alt=""
      />
      <span class="text">申请授权使用</span>
    </div>
    <div class="location">你的{{ config.name }}</div>
    <div class="tips">
      请阅读并同意
      <span class="href" @click="$refs.privacy && $refs.privacy.open()">《授权书》</span
      >，高能建站将从哔哩哔哩自动收集您的{{ config.tipDesc }}用于本次及后续表单自动填写
    </div>
    <div class="btns">
      <div class="refuse" @click="refuse">拒绝</div>
      <div class="approve" @click="approve">允许</div>
    </div>
    <agreement-location ref="privacy" @read="$emit('read')"></agreement-location>
  </base-popup>
</template>

<script>
import BasePopup from '../../base/BasePopup.vue'
import AgreementLocation from './AgreementLocation.vue'

const authTypeEnum = {
  LOCATION: 'location',
  TELEPHONE: 'telephone'
}

const authModelConfig = {
  [authTypeEnum.LOCATION]: {
    name: '位置信息',
    tipDesc: '地理位置'
  },
  [authTypeEnum.TELEPHONE]: {
    name: '登录手机号',
    tipDesc: '登录手机号'
  }
}

export default {
  name: 'AuthConfirm',
  components: { BasePopup, AgreementLocation },
  props: {
    authType: {
      type: String,
      default: authTypeEnum.LOCATION
    }
  },
  data() {
    return {
      show: false,
      config: authModelConfig[this.authType]
    }
  },
  methods: {
    open() {
      this.$emit('open')
      this.show = true
    },
    refuse() {
      this.close()
      this.$emit('refuse')
    },
    close() {
      this.show = false
    },
    approve() {
      this.$emit('confirm')
    },
    onClickOverlay() {
      this.$emit('click-overlay')
    }
  }
}
</script>

<style scoped lang="less">
.location-auth {
  width: 100%;
  border-radius: 10px 10px 0 0 !important;
  padding: 20px 16px 0;
}
.title {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  .logo {
    width: 68px;
    height: 20px;
    margin-right: 6px;
  }
  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
}
.location {
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  color: #18191c;
  margin-bottom: 16px;
}
.tips {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #61666d;
  margin-bottom: 32px;
  .href {
    color: #0a8afa;
  }
}
.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 12px;
  border-radius: 22px;
  .refuse,
  .approve {
    width: 164px;
    height: 44px;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    line-height: 44px;
  }
  .refuse {
    border: 1px solid #c9ccd0;
    border-radius: 22px!important;
    color: #9499a0;
    margin-right: 15px;
  }
  .approve {
    border-radius: 22px!important;
    background: rgba(255, 102, 153, 1);
    color: white;
  }
}
</style>
