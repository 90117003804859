import { isPreload } from '@/utils'
import biliMirror, { pbReportPv, customReportPb, techReportPb } from '@bilibili/bili-mirror'
// import Vue from 'vue'
import { isWan } from '@/config'
export const SPM_ID = '999.171.0.0'

export const REPORT_TYPE = {
  PV: 'pv',
  PV_UNLOAD: 'pv_unload',
  CLICK: 'click',
  EXPOSURE: 'exposure',
  PLAYER: 'player',
  CUSTOM: 'custom'
}

export const TECH_REPORT_TYPE = {
  PERFORMANCE: 'performance',
  ERROR: 'error',
  PLAYER: 'player',
  CUSTOM: 'custom'
}

export function reportPv(params) {
  // console.log('reportPv params: ', params)
  pbReportPv(params)
}

export function report(eventId, params, otherOptions = {}) {
  if (!eventId) return
  customReportPb({
    eventId,
    ...otherOptions,
    ...(params ? { msg: params } : {})
  })
}

export function reportClick(eventId, params, otherOptions = {}) {
  report(eventId, params, { type: REPORT_TYPE.CLICK, ...otherOptions })
}

export function reportExposure(eventId, params) {
  report(eventId, params, { type: REPORT_TYPE.EXPOSURE })
}

export function reportTech(eventId, params, otherOptions = {}) {
  if (!eventId) return
  techReportPb({
    eventId,
    ...otherOptions,
    ...(params ? { msg: params } : {})
  })
}

export function reportTechError(eventId, params) {
  //
  if (!isPreload()) {
    reportTech(eventId, params, { type: TECH_REPORT_TYPE.ERROR })
  }
}

export function initBiliMirror(Vue, options = {}) {
  Vue.use(biliMirror, {
    origin: 'sycpb',
    module: 'mgk-gaoneng-h5',
    spmId: SPM_ID,
    ...options,
    pbOptions: {
      autoPv: false,
      logLevel: process.env.NODE_ENV === 'development' ? 1 : 0,
      ...(options.pbOptions ? options.pbOptions : {})
    }
  })
  Vue.prototype.$reportWarn = reportTechError
}
const getOperator = () => {
  if (isWan) {
    return {}
  } else {
    const cookies = document.cookie.split(';')
    const nameIndex = cookies.findIndex((c) => c.includes('username='))
    if (nameIndex !== -1) {
      return {
        operator: cookies[nameIndex].split('=')[1]
      }
    } else {
      return {}
    }
  }
}
const getBaseInfo = () => {
  return {
    is_lan: Number(!isWan),
    ...getOperator()
  }
}
export const reportClick179 = (eventId, params) => {
  reportClick(eventId, { ...getBaseInfo(), ...params }, { otherSpmId: '999.179' })
}
