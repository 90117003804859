<template>
  <div class="address-wrap">
    <div @click="onClick">
      <normal-input
        v-model="addressStr"
        class="cc-form-input-wrap select-input"
        type="text"
        :readonly="true"
        placeholder="请输入地址"
      >
      </normal-input>
    </div>
    <popup-picker-multi-cols
      v-if="columns && columns.length"
      ref="picker"
      :levels="data.min_address + 1"
      :columns="columns"
      @confirm="onConfirm"
    ></popup-picker-multi-cols>
    <normal-input
      v-if="data.is_detail_address"
      v-model="data.address.detail"
      class="cc-form-input-wrap mt14"
      type="text"
      :maxlength="200"
      placeholder="请输入详细地址"
      @focus="focus"
      @change="$emit('change')"
    />
  </div>
</template>
<script>
import NormalInput from './NormalInput'
import PopupPickerMultiCols from './picker/PopupPickerMultiCols'

export default {
  name: 'AddressComp',
  components: {
    NormalInput,
    PopupPickerMultiCols
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    areaList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columns: [],
      addressStr: ''
    }
  },
  watch: {
    areaList(val) {
      this.columns = val
    }
  },
  methods: {
    onConfirm({ names, ids }) {
      this.addressStr = names.join('/')
      this.data.address = {
        province: {
          name: names[0] || '',
          id: ids[0] || ''
        },
        city: {
          name: names[1] || '',
          id: ids[1] || ''
        },
        county: {
          name: names[2] || '',
          id: ids[2] || ''
        }
      }
      this.$emit('change')
    },
    onClick() {
      this.$refs.picker && this.$refs.picker.open()
      this.focus()
    },
    focus() {
      this.$emit('focus')
    }
  }
}
</script>
<style lang="less" scoped>
.address-wrap {
  width: 100%;
  .address-detail {
    margin-top: 10px;
  }
}
.select-input {
  padding-right: 30px;
}
.mt14 {
  margin-top: 14px !important;
}
</style>
