<template>
  <div class="cc-form-input-wrap" :class="{ 'not-input': readonly }">
    <slot name="prepend" />
    <input
      ref="input"
      v-model="model"
      :maxlength="maxlength"
      :name="name"
      :placeholder="placeholder"
      :readonly="readonly"
      class="cc-form-input"
      type="text"
      @focus="onFocus"
    />
    <slot name="append" />
  </div>
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: '请输入'
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: 1024
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  methods: {
    onFocus() {
      // 只用input样式不用input功能
      if (this.readonly) {
        document.activeElement.blur()
      } else {
        this.$emit('focus')
      }
    },
    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>
<style lang="less">
.cc-form-input-wrap {
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  overflow: hidden;
  padding: 11px 12px;
  line-height: 1;
  color: #666;
  &.van-cell {
    background: transparent;
  }
  &.not-input {
    .cc-form-input {
      text-overflow: ellipsis;
    }
  }
  .cc-form-input {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-appearance: none;
    position: relative;
    background: transparent;
    box-sizing: border-box;
    width: 100%;
    height: 20px;
    line-height: 20px;
    border: none;
    font-size: 14px;
    &::placeholder {
      text-overflow: ellipsis;
      color: rgba(74, 80, 94, 0.3);
    }
  }
}
</style>
