<template>
  <base-popup v-model="show" position="bottom">
    <van-picker
      :columns="cols"
      show-toolbar
      :item-height="itemHeight"
      :visible-item-count="visibleItemCount"
      @confirm="onConfirm"
      @cancel="onCancel"
    ></van-picker>
  </base-popup>
</template>
<script>
import { get } from 'lodash'
import mixin from './mixin'

export default {
  name: 'PopupPickerMultiCols',
  mixins: [mixin],
  props: {
    textField: {
      type: String,
      default: 'name'
    },
    idField: {
      type: String,
      default: 'id'
    },
    childrenField: {
      type: String,
      default: 'children'
    },
    levels: {
      type: Number,
      required: true
    }
  },
  data() {
    return {}
  },
  created() {
    this.cols = this.transferData(this.columns)
  },
  methods: {
    transferData(nodes, curLevel = 1) {
      if (curLevel === this.levels) {
        return nodes.map((node) => {
          node.text = node[this.textField]
          delete node[this.childrenField]
          return node
        })
      } else {
        return nodes.map((node) => {
          node.text = node.name
          const children = node[this.childrenField]
          if (children && children.length > 0) {
            node[this.childrenField] = this.transferData(children, curLevel + 1)
          }
          return node
        })
      }
    },
    open() {
      this.show = true
    },
    close() {
      this.show = false
    },
    onCancel() {
      this.close()
      this.$emit('cancel')
    },
    onConfirm(valueArr, idxArr) {
      const cValueArr = Array.isArray(valueArr) ? valueArr : [valueArr[this.textField]]
      const cIdxArr = Array.isArray(idxArr) ? idxArr : [idxArr]

      this.close()
      const ids = this.getIds(cIdxArr)
      this.$emit('confirm', {
        names: cValueArr,
        ids
      })
    },
    getIds(values) {
      const ids = []

      let lodashGetStr = ''
      values.forEach((v, lv) => {
        if (lv === 0) {
          lodashGetStr += `[${v}]`
        } else {
          lodashGetStr += `.${[this.childrenField]}[${v}]`
        }
        const id = get(this.columns, lodashGetStr + `.${this.idField}`)
        ids.push(id)
      })
      return ids
    }
  }
}
</script>

<style scoped lang="less">
.van-popup {
  width: 375px;
  margin: 0 auto;
  left: 0;
  right: 0;
  border-radius: 0 !important;
}
</style>
