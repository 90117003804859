import '@vant/touch-emulator'
import request from './request'
import impr from './impr'
import reportUI from './reportUI'
import VConsole from 'vconsole'
import { initBiliMirror } from '@/plugin/bilibiliMirror'
import {
  Dialog,
  Button,
  Empty,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  Popup,
  Field,
  Icon,
  Toast,
  Picker
} from 'vant'

import iLoading from '../components/Loading'

const install = (Vue) => {
  Vue.use(Empty)
  Vue.use(Button)
  Vue.use(Dialog)
  Vue.use(Toast)
  Vue.use(Icon)
  Vue.use(Radio)
  Vue.use(RadioGroup)
  Vue.use(Checkbox)
  Vue.use(CheckboxGroup)
  Vue.use(Popup)
  Vue.use(Field)
  Vue.use(Picker)
  Vue.component('ILoading', iLoading)
  Vue.use(request)
  Vue.use(impr)
  Vue.use(reportUI)
  initBiliMirror(Vue)
}

if (process.env.NODE_ENV === 'development') {
  window.vConsole = new VConsole()
  console.info('Hello world')
}
export default {
  install
}
