<template>
  <div class="form-item" :class="{ 'form-item-error': !!error }">
    <div class="form-item-label">
      <slot name="label">{{ label }}</slot>
    </div>
    <div class="form-item-value">
      <slot />
    </div>
    <div v-if="error" class="form-item-error-msg ellipsis">
      {{ error }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'FormItem',
  props: {
    label: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    }
  },
  methods: {
    validate() {}
  }
}
</script>
<style lang="less"></style>
