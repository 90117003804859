<template>
  <CcDownload :data="data" :show-logo="false" :show-content="false" :download="download" />
</template>
<script>
import CcDownload from '../components/Download'

export default {
  name: 'DownloadBg',
  components: {
    CcDownload
  },
  provide() {
    return {
      compName: this.component.name,
      compId: this.component.id
    }
  },
  props: {
    component: {
      type: Object,
      default: null
    },
    download: {
      type: Object,
      default: null
    }
  },
  computed: {
    data() {
      return this.component.data || {}
    }
  }
}
</script>
