<template>
  <a
    v-if="form && form.id"
    :class="{ 'is-editor': isEditor, 'is-prod': !isEditor }"
    :href="'#' + formId"
    :style="{ backgroundColor: color, ...style }"
    class="location-button"
    @click="onClick"
  >
    <i class="icon iconfont icontucengyemianbiaodanicon"></i>
  </a>
</template>

<script>
import { get, set } from 'lodash'

export default {
  name: 'LocationButton',
  props: {
    blocks: {
      type: Array,
      default: () => []
    },
    context: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      appEle: null,
      canvasAreaEle: null,
      canvasScrollTop: 0,
      curFormId: ''
    }
  },
  computed: {
    forms() {
      const forms = []
      const blocks = this.blocks
      forms.push(...blocks)
      for (let i = 0; i < blocks.length; i++) {
        forms.push(...blocks[i].components)
      }
      return forms
    },
    form() {
      let form = {}
      const selectedForms = [] // 当前已勾选的表单
      let oldFormId = '' // 待替换的表单id

      this.forms.forEach((item) => {
        const { name } = item
        if (name && name.indexOf('form') > -1) {
          const { status, color } = get(item, 'data.advanceSetting.locationButton')
          const themeColor = get(item, 'data.style.themeColor')
          if (!color) {
            set(item, 'data.advanceSetting.locationButton.color', themeColor)
          }

          if (status) {
            selectedForms.push(item)
          }
        }
      })

      for (let i = 0; i < selectedForms.length; i++) {
        const item = selectedForms[i]
        const formId = item.id
        const len = selectedForms.length

        if (len === 1) {
          form = item
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.curFormId = formId
        } else {
          if (this.curFormId !== formId) {
            form = item
            oldFormId = this.curFormId
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.curFormId = formId
            this.$message.warning('只允许开启一个定位按钮，我们已为您自动关闭其他已开启的定位按钮')
            break
          }
        }
      }

      this.forms.forEach((item) => {
        if (item.id !== oldFormId) return
        let locationButton = get(item, 'data.advanceSetting.locationButton')
        locationButton = { ...locationButton, status: false }
        set(item, 'data.advanceSetting.locationButton', locationButton)
      })

      return form
    },
    color() {
      return get(this.form, 'data.advanceSetting.locationButton.color')
    },
    formId: {
      get(val) {
        if (val) {
          this.$emit('show')
        }
        return get(this.form, 'id')
      }
    },
    isEditor() {
      return this.context === 'editor'
    },
    style() {
      if (this.isEditor) {
        if (!this.canvasAreaEle) return {}
        return {
          top: this.canvasScrollTop + Math.min(579, this.canvasAreaEle.clientHeight - 248) + 'px'
        }
      }
      return {}
    }
  },
  watch: {
    blocks: {
      deep: true,
      handler() {}
    }
  },
  mounted() {
    this.getAppEle()
    this.onCanvasScroll()
  },
  methods: {
    getAppEle() {
      this.appEle = document.querySelector('#app')
    },
    onCanvasScroll() {
      this.canvasAreaEle = document.querySelector('.canvas-area')
      if (!this.canvasAreaEle) return
      this.canvasAreaEle.addEventListener('scroll', () => {
        this.canvasScrollTop = this.canvasAreaEle.scrollTop
      })
    },
    onClick() {
      window.hasClickFormLocationButton = true
    }
  }
}
</script>

<style lang="less" scoped>
.location-button {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    color: white;
    font-size: 18px;
  }
  &.is-editor {
    position: absolute;
    top: 579px;
    right: 20px;
  }
  &.is-prod {
    position: fixed;
    bottom: 40px;
    margin-left: 307px;
  }
}
</style>
