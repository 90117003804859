import './picker.less'
import BasePopup from '../../base/BasePopup'

export default {
  data() {
    return {
      show: false,
      cols: []
    }
  },
  components: { BasePopup },
  props: {
    visibleItemCount: {
      type: Number,
      default: 5
    },
    itemHeight: {
      type: Number,
      default: 50
    },
    columns: {
      type: Array,
      default: () => []
    }
  }
}
