<template>
  <div v-if="download && isAndroidImageDownload">
    <div class="cc-image-wrap" @click="onClick">
      <img v-if="image" ref="image" :src="webpSafe(image)" alt="" class="cc-image" />
      <div v-else class="cc-image-empty">
        <img alt="" class="empty-image" src="../../assets/image_empty.png" />
      </div>
    </div>
    <DownloadConfirmModal ref="modal" @download="download.action" />
  </div>
  <LinkComp v-else :link="link" class="cc-image-wrap">
    <img v-if="image" ref="image" :src="webpSafe(image)" alt="" class="cc-image" />
    <div v-else class="cc-image-empty">
      <img alt="" class="empty-image" src="../../assets/image_empty.png" />
    </div>
  </LinkComp>
</template>
<script>
import LinkComp from './Link'
import { webpSafe } from '../utils'
import mixin from './image/mixin'
import DownloadConfirmModal from './download/DownloadConfirmModal'

export default {
  name: 'CcImage',
  components: {
    DownloadConfirmModal,
    LinkComp
  },
  mixins: [mixin],
  props: {
    image: {
      type: String,
      default: ''
    },
    link: {
      type: Object,
      default: () => {}
    },
    download: {
      type: Object,
      default: null
    },
    isAndroidImageDownload: {
      type: Boolean
    }
  },
  data() {
    return {
      webpSafe
    }
  },
  methods: {
    onClick() {
      if (this.download?.canTriggerDownload) {
        this.$refs.modal && this.$refs.modal.open()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.cc-image-wrap {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center;
  .cc-image {
    width: 100%;
    height: 100%;
  }
  .cc-image-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #f5f5f5;
    .empty-image {
      width: 76px;
      height: 57px;
    }
  }
}
</style>
