<template>
  <CcButton :class="buttonClass" :content="content" :style="style" class="shape-rect-c" />
</template>
<script>
import CcButton from '../components/Button'
import { webpSafe } from '../utils'

export default {
  name: 'ButtonComp',
  components: {
    CcButton
  },
  provide() {
    return {
      compName: this.component.name,
      compId: this.component.id
    }
  },
  props: {
    component: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    data() {
      return this.component.data
    },
    content() {
      return this.data?.content || {}
    },
    style() {
      const { borderWidth, borderRadius, fontSize, background, ...rest } = this.data?.style || {}
      const { backgroundType, color, image, gradient } = background || {}
      const getBg = () => {
        if (backgroundType === 'image') {
          return {
            backgroundImage: `url(${webpSafe(image)})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'repeat'
          }
        } else if (backgroundType === 'gradient') {
          return {
            background: `linear-gradient(${gradient.deg}deg, ${gradient.colors[0]}, ${gradient.colors[1]})`
          }
        } else {
          return { backgroundColor: color || '#0a8afa' }
        }
      }
      return {
        borderRadius: borderRadius + 'px',
        borderWidth: borderWidth + 'px',
        fontSize: fontSize + 'px',
        ...getBg(),
        ...rest
      }
    },
    buttonClass() {
      const { animation } = this.data?.style || {}
      return `animation-${animation}`
    }
  }
}
</script>
<style>
.shape-rect-c {
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
}
</style>
