import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  props: ['component', 'context'],
  components: {
    Swiper,
    SwiperSlide
  },
  provide() {
    return {
      compName: this.component.name,
      compId: this.component.id
    }
  },
  watch: {
    autoplay(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.swiperOptions.autoplay = newValue
        if (newValue) {
          return this.swiper.autoplay.start()
        } else {
          return this.swiper.autoplay.stop()
        }
      }
    }
  },
  created() {},
  computed: {
    inEditor() {
      return this.context === 'editor'
    },
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
    data() {
      return this.component.data || {}
    },
    content() {
      return this.data.content || {}
    },
    items() {
      return this.content.imageCarousel || {}
    },
    config() {
      return this.content.carouselConfig || {}
    },
    autoplay() {
      return this.config.autoplay
    }
  },
  mounted() {},
  methods: {
    handleResize() {
      this.swiper?.update()
    }
  }
}
