import { MINI_item_click, MINI_item_cvr_click, MINI_assistant_click } from '../constants/reportEvents'
import { inBiliApp } from '@bilibili/js-bridge'
import { getQuery } from '@/utils'

export default {
  props: ['component', 'context'],
  provide() {
    return {
      compName: this.component.name,
      compId: this.component.id
    }
  },
  computed: {
    isEditor() {
      return this.context === 'editor'
    },
    data() {
      return this.component.data || {}
    },
    content() {
      return this.data?.content || {}
    },
    buttonStyle() {
      const { borderWidth, borderRadius, backgroundImage, fontSize, ...rest } = this.data?.buttonStyle || {}
      return {
        borderRadius: borderRadius + 'px',
        borderWidth: borderWidth + 'px',
        fontSize: fontSize + 'px',
        ...rest
      }
    },
    buttonClass() {
      const { animation } = this.data?.buttonStyle || {}
      return `animation-${animation}`
    }
  },
  methods: {
    reportClick(ev) {
      this.$reportUI(
        MINI_item_click,
        {
          item_name: this.component.name,
          item_id: this.component.id,
          event_type: 'wxCopyEvent'
        },
        ev
      )
      this.$reportUI(
        MINI_item_cvr_click,
        {
          event_type: 'wxCopyEvent'
        },
        ev
      )
    },
    reportClickLink(ev) {
      this.$reportUI(
        MINI_item_click,
        {
          item_name: this.component.name,
          item_id: this.component.id,
          event_type: 'wxLinkEvent'
        },
        ev
      )
      this.$reportUI(
        MINI_item_cvr_click,
        {
          event_type: 'wxLinkEvent'
        },
        ev
      )
      this.$reportUI(
        MINI_assistant_click,
        {
          event_type: 'wxLinkEvent'
        },
        ev
      )
    },
    linkGet() {
      // 获客助手添加微信
      let url = this.content?.weixin?.linkUrl
      if (inBiliApp && url) {
        const query = {
          pageId: getQuery('pageId'),
          trackId: window._globalData.trackId,
          requestId: getQuery('request_id'),
          id: this.content?.weixin?.linkId
        }
        if (query.trackId || query.requestId) {
          const macro = `${query.pageId}_${query.trackId || query.requestId}_${query.id}`
          url = url.replace('__PAGEID_TRACKID_LINKDATAID__', macro)
        }
        window.open(url)
      }
    }
  }
}
