<template>
  <div class="weixin-button-large-wrap" :style="buttonStyle" :class="buttonClass">
    <div style="width: 100%; height: 100%" @click="onBtnClick">
      <div class="inner-wrapper">
        <img src="../../assets/wechat_white.svg" alt="" class="logo" />
        <div class="text">{{ content.buttonText }}</div>
      </div>
    </div>
    <wx-modal v-if="!isEditor" ref="modal" :data="content.modal" :content="content"></wx-modal>
  </div>
</template>

<script>
import mixin from '../weixin/mixin'
import WxModal from '../components/weixin/WxModal'

export default {
  components: {
    WxModal
  },
  mixins: [mixin],
  methods: {
    onBtnClick(ev) {
      if (this.content?.weixin?.type === 100) {
        this.linkGet()
        this.reportClickLink(ev)
      } else {
        this.$refs.modal.open()
        this.reportClick(ev)
      }
    }
  }
}
</script>
<style scoped lang="less">
.weixin-button-large-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #1aad19;
  .inner-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 6px;
  }
  .text {
    line-height: 14px;
  }
}
</style>
