/***
 * 生成的页面里用到的ajax 请求，同配置系统的不同
 * **/
import qs from 'qs'
import http from 'axios'
import { baseOpenURL as baseURL } from '@/config/index'

http.defaults.timeout = 30000
http.defaults.withCredentials = true
http.defaults.headers.post['Content-Type'] = 'application/json'
http.defaults.headers.put['Content-Type'] = 'application/json'

const escapeJsonContentType = 'application/x-www-form-urlencoded'
const methods = ['get', 'delete', 'head', 'jsonp', 'post', 'put', 'patch']
const methodsWidthBody = ['post', 'put', 'patch']

function install(Vue) {
  const ajax = {}

  methods.forEach((method) => {
    ajax[method] = getRequest(method)
  })

  // ajax封装
  function getRequest(method) {
    return function (url, data = {}, opts = {}) {
      const defaultOptions = {
        method,
        json: true,
        baseURL,
        headers: {}
      }

      const options = Object.assign({}, defaultOptions, opts, { url })
      // Object.assign(options, {url})
      // x-www-form格式处理
      if (!options.json) {
        options.headers['Content-Type'] = escapeJsonContentType
        data = qs.stringify(data)
      }

      if (method === 'get' && !!data) {
        const params = {}

        Object.keys(data).forEach((key) => {
          params[key] = Array.isArray(data[key]) ? data[key].join(',') : data[key]
        })

        data = params
      }

      if (methodsWidthBody.includes(method)) {
        options.data = data
      } else {
        options.params = data
      }

      return http
        .request(options)
        .then((res) => {
          return doResponse(res, options)
        })
        .catch((error) => {
          return doError(error, url, options.slient)
        })
    }
  }

  // 响应处理
  function doResponse(res, options) {
    const { status, data = {} } = res
    const msg = '网络异常，请稍后再试'

    if (status !== 200) {
      return Promise.reject(msg)
    }

    if (options.useErrData) {
      return data
    }
    if (data.code !== 0 && data.status !== 'success') {
      const { message = '网络异常' } = data
      return Promise.reject(message)
    }
    return data
  }

  // 异常处理
  function doError(error) {
    const message = (error && error.message) || error || '网络异常'

    return Promise.reject(message)
  }

  Vue.prototype.$request = ajax
}

export default {
  install
}
