<template>
  <LinkComp :link="link" :report-info="reportInfo" class="cc-image-wrap">
    <!-- 用于图片加载埋点 -->
    <img v-if="image" ref="image" :src="webpSafe(image)" style="display: none" />
    <!-- 用于实际展现 -->
    <div v-if="image" :style="{ backgroundImage: `url(${webpSafe(image)})` }" class="cc-image"></div>
    <div v-else class="cc-empty-image">
      <img :style="{ width: emptyImgWidth }" alt="" src="../../assets/image_empty.png" />
    </div>
  </LinkComp>
</template>
<script>
import LinkComp from './Link'
import { webpSafe } from '../utils'
import mixin from './image/mixin'

// 该组件用于多图中的图片，包括两图、三图和轮播图
// 在多图中，空白图的大小和默认图片比例与单图不同
export default {
  name: 'CcImageV2',
  components: {
    LinkComp
  },
  mixins: [mixin],
  props: {
    reportInfo: {
      type: Object,
      default: () => ({})
    },
    image: {
      type: String,
      default: ''
    },
    link: {
      type: Object,
      default: () => {}
    },
    emptyImgWidth: {
      type: String,
      default: '30%'
    }
  },
  data() {
    return {
      webpSafe
    }
  }
}
</script>
<style lang="less" scoped>
.cc-image-wrap {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  .cc-image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .cc-empty-image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: auto;
    }
  }
}
</style>
