<template>
  <div class="cc-richtext-wrap">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="ql-editor cc-richtext" v-html="html"></div>
  </div>
</template>
<script>
export default {
  props: {
    component: {
      type: Object,
      default: null
    },
    context: {
      type: String,
      default: ''
    }
  },
  computed: {
    data() {
      return this.component.data
    },
    content() {
      return this.data?.content || {}
    },
    html() {
      const { content = '请添加文本内容' } = this.content || {}

      return unescape(content)
    }
  },
  watch: {
    html() {
      this.context === 'editor' && this.onHeightChange()
    }
  },
  methods: {
    onHeightChange() {
      this.$nextTick(() => {
        this.data.layout.height = this?.$el.offsetHeight || 0
        this.$nextTick(() => this.$emit('block-auto-expand'))
      })
    }
  }
}
</script>
<style scoped>
.cc-richtext-wrap {
  padding: 0 !important;
}
</style>
