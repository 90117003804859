<template>
  <div class="weixin-button-detail">
    <img v-if="content.logo" :src="content.logo" alt="" class="logo" />
    <img v-else src="../../assets/wechat_logo.svg" alt="" class="logo" />
    <div class="text">{{ content.text }}</div>
    <div class="button" :class="buttonClass" :style="buttonStyle" @click="onBtnClick">
      <div class="text-wrapper">
        {{ content.buttonText }}
      </div>
    </div>
    <wx-modal v-if="!isEditor" ref="modal" :data="content.modal" :content="content"></wx-modal>
  </div>
</template>

<script>
import mixin from './mixin'
import WxModal from '../components/weixin/WxModal'

export default {
  components: {
    WxModal
  },
  mixins: [mixin],
  methods: {
    onBtnClick(ev) {
      if (this.content?.weixin?.type === 100) {
        this.linkGet()
        this.reportClickLink(ev)
      } else {
        this.$refs.modal.open()
        this.reportClick(ev)
      }
    }
  }
}
</script>
<style scoped lang="less">
.weixin-button-detail {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 8px 14px;
  background-color: white;
  .logo {
    min-width: 32px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .text {
    width: calc(100% - 132px);
    padding-left: 8px;
    padding-right: 20px;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .button {
    float: right;
    min-width: 100px;
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #1aad19;
    box-sizing: border-box;
    .text-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      white-space: nowrap;
    }
  }
}
</style>
