import Vue from 'vue'
import { biliBridge, isSupport, inBiliApp } from '@bilibili/js-bridge'
import { Children } from './constant'
export const getCityId = async (
  location: {
    longtitude: string
    latitude: string
  } = { longtitude: '', latitude: '' },
  ids: { formId: string | number; itemId: string }
) => {
  return Vue.prototype.$request
    .get(
      'v1/address/lng_lat' +
        `?lng=${location.longtitude.trim()}&lat=${location.latitude.trim()}&form_id=${ids.formId}&form_item_id=${
          ids.itemId
        }`
    )
    .then((res: { data: any }) => {
      return Promise.resolve(res.data)
    })
    .catch((err: any) => {
      return Promise.reject(new Error(err))
    })
}

export const getCityIdAndNearest = async (
  location: {
    longtitude: string
    latitude: string
  } = { longtitude: '', latitude: '' },
  ids: { formId: string | number; itemId: string },
  product: string = ''
) => {
  product = product.replace('+', '%2B')
  return Vue.prototype.$request
    .get(
      'v1/address/nearest' +
        `?lng=${location.longtitude.trim()}&lat=${location.latitude.trim()}&form_id=${ids.formId}&form_item_id=${
          ids.itemId
        }&product=${product}`
    )
    .then((res: { data: any }) => {
      return Promise.resolve(res.data)
    })
    .catch((err: any) => {
      return Promise.reject(new Error(err))
    })
}
export const getShopNearestList = async (
  location: {
    longtitude: string
    latitude: string
  } = { longtitude: '', latitude: '' },
  ids: { formId: string | number; itemId: string },
  cityId: string = '',
  product: string = ''
) => {
  product = product.replace('+', '%2B')
  return Vue.prototype.$request
    .get(
      'v1/address/nearest/service/list' +
        `?lng=${location.longtitude.trim()}&lat=${location.latitude.trim()}&form_id=${ids.formId}&form_item_id=${
          ids.itemId
        }&china_admin_code=${cityId}&product=${product}`
    )
    .then((res: { data: any }) => {
      const { data = [] } = res || {}
      let index = 1
      let idx = 0
      const list = data.map((item: { shop_name: string; distance: string; shop_address: string }) => ({
        name: item.shop_name || '',
        id: index++ || '',
        idx: idx++,
        distance: item.distance || '',
        address: item.shop_address || '',
        w: 0,
        children: []
      }))
      return Promise.resolve(list)
    })
    .catch((err: any) => {
      return Promise.reject(new Error(err))
    })
}

interface Node {
  id: number
  name: string
}
export const transferData = (nodes: Children[]) => {
  // 去重
  const idSet = new Set()
  const res: Node[] = []
  nodes.forEach((node) => {
    if (!idSet.has(node.id)) {
      idSet.add(node.id)
      res.push({
        id: node.id,
        name: node.name
      })
    }
  })
  return res
}
/**
 * 获取idxs.length+1层级的children
 * @param initChildren 初始的childern列表
 * @param idxs 每一个层级选择的childern index
 * @returns 获取idxs.length+1层级的选择项列表
 */

export const getPickerOptions = (initChildren: Children[], idxs: number[] = []) => {
  let children = initChildren
  for (const idx of idxs) {
    children = children[idx].children!
    if (!children) {
      return []
    }
  }
  const data = transferData(children)
  return data.map((i, index) => {
    return {
      id: i.id,
      name: i.name,
      idx: index
    }
  })
}

/**
 * 通过jsb获取用户实时的位置
 * @param successCall 成功后的回调
 * @param failCall 失败后的回调
 */
export const getLocation = (successCall: (lng: string, lat: string) => void, failCall: () => void) => {
  // todo
  if (inBiliApp) {
    isSupport('ability.getLocation').then((support) => {
      if (support) {
        biliBridge.callNative({
          method: 'ability.getLocation',
          data: {
            bizName: '4',
            type: 'real',
            hintMsg: '我们可能会获取您的位置信息用于本次及后续表单自动填写。不授权该权限不影响App其他功能使用。'
          },
          callback: async (info) => {
            const { location, code } = info || {}
            if (code === 0) {
              const coor = location?.coordinate?.coor
              const lat = coor.split(',')[0]
              const lng = coor.split(',')[1]
              successCall(lng, lat)
            } else {
              failCall()
            }
          }
        })
      }
    })
  } else {
    // successCall('120.1', '30.2')
    failCall()
  }
}
