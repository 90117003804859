<template>
  <div v-if="isEmpty" class="cc-float-form">
    <slot name="title"></slot>
    <img style="width: 100%" :src="placeholder" />
  </div>
  <div v-else-if="error" class="cc-float-form">请选择表单</div>
  <div v-else ref="form" class="cc-float-form">
    <slot name="title"></slot>
    <FormItem v-for="item in items" :key="item.id" :label="item.label" :type="item.type" :name="item.name">
      <FormInput
        v-if="item.type === 'input'"
        v-model="item.value"
        :name="item.id"
        :type="item.type"
        :maxlength="200"
        :placeholder="item.placeholder"
      />
      <Telephone
        v-if="item.type === 'telephone'"
        v-model="item.value"
        class="tel-num"
        type="tel"
        border
        mode="simple"
        placeholder="请输入手机号码"
        :is-from-video="true"
        :show-prefix="showPrefix"
        :show-auto-fill="showAutoFill"
        :data="item"
      >
      </Telephone>
    </FormItem>
    <div v-if="button" class="form-item form-item-btn">
      <van-button tag="a" :style="buttonStyle" class="submit-btn" type="info" @click="submit">{{
        button.label
      }}</van-button>
    </div>
  </div>
</template>
<script>
import { serializeForm } from '../form/utils'
import FormItem from '../form/FormItem'
import FormInput from '../form/NormalInput'
import Telephone from '../form/Telephone'
import { MINI_list_fail, MINI_list_form_submit_click, MINI_list_success } from '../../constants/reportEvents'

export default {
  name: 'CcFloatForm',
  components: {
    FormItem,
    FormInput,
    Telephone
  },
  inject: ['pageId', 'pageQuery'],
  props: {
    buttonStyle: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    },
    error: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    showPrefix: {
      type: Boolean,
      default: false
    },
    showAutoFill: {
      type: Boolean,
      default: false
    },
    cardType: {
      type: String,
      default: ''
    }
  },
  computed: {
    items() {
      return (this.data.items || []).filter((item) => !!item)
    },
    button() {
      return this.data.button || {}
    },
    form() {
      return this.data?.form || {}
    },
    isEmpty() {
      return !this.form.id
    },
    compType() {
      const typeMap = {
        end: 'end_card',
        small: 'small_card',
        form: 'large_card'
      }
      return typeMap[this.cardType]
    }
  },
  methods: {
    validate() {},
    submit() {
      const formData = serializeForm(this.form.id, this.items)
      this.$reportUI(MINI_list_form_submit_click, {
        item_id: this.compId,
        comp_type: this.compType
      })
      this.loading = true
      this.$request
        .post(`v1/form_data/page/${this.pageId}?${this.pageQuery || ''}`, formData, {})
        .then(() => {
          this.$toast(this.data?.successMessage || '提交成功')
          this.$parent.$emit('confirm')

          this.$reportUI(MINI_list_success, {
            item_id: this.compId,
            comp_type: this.compType
          })
        })
        .catch((error) => {
          const message = (error && error.message) || error

          this.$dialog.alert({
            message,
            className: 'over-popup'
          })

          this.$reportUI(MINI_list_fail, {
            item_id: this.compId,
            comp_type: this.compType
          })
        })
    }
  }
}
</script>
<style lang="less"></style>
