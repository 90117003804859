<template>
  <div class="cc-float-card">
    <div class="app-main">
      <img v-if="app.logo" class="app-logo" :src="app.logo" />
      <div v-else class="app-logo empty" />
      <div class="app-content">
        <div class="app-name ellipsis">{{ app.title || '应用名称' }}</div>
        <div class="app-desc">
          <div class="app-text">{{ app.description || '应用文案描述' }}</div>
        </div>
      </div>
    </div>
    <div class="app-button">
      <ButtonComp :content="button" :style="buttonStyle" :download="download" />
    </div>
  </div>
</template>
<script>
import ButtonComp from '../Button'

export default {
  name: 'CcFloatBigCard',
  components: {
    ButtonComp
  },
  props: {
    app: {
      type: Object,
      default: null
    },
    button: {
      type: Object,
      default: () => {}
    },
    buttonStyle: {
      type: Object,
      default: null
    },
    download: {
      type: Object,
      default: null
    }
  }
}
</script>
