import qs from 'qs'
import Vue from 'vue'
import { inBiliApp, isSupport, biliBridge } from '@bilibili/js-bridge'
import { isUndefined } from 'lodash'
import { getQuery } from '@/utils/index'
import { MINI_jsb_callback, MINI_jsb_not_support, MINI_jsb_request } from './constants/reportEvents'

export const isSupportWebp = () => {
  try {
    return document.createElement('canvas').toDataURL('image/webp', 0.5).indexOf('data:image/webp') === 0
  } catch (err) {
    return false
  }
}

export const webpSafe = (url) => {
  url = url.replace(/^http:/, 'https:') // 兼容三连上传图片为http的问题
  if (!url) return ''
  if (isSupportWebp()) {
    return url
  } else {
    // IOS 不支持零宽断言，改为分组匹配
    // const reg = /(?<=\.(jpg|jpeg|png|gif)).*/
    const reg = /(.*)(\.(jpg|jpeg|png|gif))(@.*)?$/
    const res = url.match(reg)
    if (res) {
      return res[1] + res[2]
    } else {
      return url
    }
  }
}

export const getAppZoom = () => {
  const appEle = document.querySelector('#app')

  if (appEle) {
    return window.getComputedStyle(appEle).zoom || 1
  }
  return 1
}

export const getEleTop = (ele) => {
  if (!ele) return 0

  const zoom = getAppZoom()
  const { top } = ele.getBoundingClientRect()
  return top * zoom
}

export const getOffsetTop = (ele) => {
  // offsetTop是obj对象距离直接定位的父级元素的top值
  let t = ele.offsetTop
  // 不知到循环要执行多少次时使用
  while ((ele = ele.offsetParent)) {
    t += ele.offsetTop
  }
  return t * getAppZoom()
}

export const getViewHeight = () => {
  // 下拉弹性效果会使得scrollTop小于0
  return document.documentElement.clientHeight + Math.max(0, document.documentElement.scrollTop)
}

export const getScrollHeight = () => {
  // 下拉弹性效果会使得scrollTop小于0
  return Math.max(0, document.documentElement.scrollTop)
}
export const getScrollLeft = () => {
  return Math.max(0, document.documentElement.scrollLeft)
}

export const getFirstScreen = (ele) => {
  if (!ele) return false

  try {
    const htmlHeight = document.documentElement.clientHeight
    const { top, height } = ele.getBoundingClientRect()
    const zoom = getAppZoom()
    return htmlHeight - (document.documentElement.scrollTop + top * zoom) > height * zoom * 0.75
  } catch (e) {
    return false
  }
}

const ua = window.navigator.userAgent || ''
const platform =
  ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1
    ? 'android'
    : ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    ? 'ios'
    : 'web'

export const isIOS = platform === 'ios'
export const isAndroid = platform === 'android'
export const isLongIOS = isIOS && window.screen.height > 810
export const isSafari = ua.includes('Safari') && !ua.includes('Chrome')

export const isCmWebview = () => {
  // 暂时按照track_id判断，之后jsb会增加判断api
  const { track_id: trackId } = window.alita.pageInfo || {}
  return !!trackId
}

export const isAd = isCmWebview

export const isPreview = () => {
  return /tetris\/preview/.test(window.location.href) || +getQuery('is_preview')
}

export const getFormButtonExpInfo = () => {
  if (isUndefined(window._globalData)) {
    window._globalData = {}
  }
  if (isUndefined(window._globalExp)) {
    window._globalExp = {}
  }
  const containerHeight = window._globalData?.containerHeight || document.documentElement.clientHeight
  const pageHeight = window._globalData.pageHeight || document.documentElement.scrollHeight

  if (isUndefined(window._globalData.pageHeight)) {
    window._globalData.pageHeight = pageHeight
  }
  if (isUndefined(window._globalData.containerHeight)) {
    window._globalData.containerHeight = containerHeight
  }
  const res = {
    form_button_hit: window._globalExp.form_button_hit,
    is_add_button: window._globalExp.is_add_button,
    container_height: containerHeight,
    page_height: pageHeight,
    scroll_top: getViewHeight()
  }
  if (inBiliApp) {
    const xPercent = window._globalExp.form_button_view_limit || 0
    const formButtonAdd = window._globalExp.form_button_add
    if (!isUndefined(formButtonAdd)) {
      res.form_button_add = formButtonAdd
      res.form_button_view_limit = xPercent
      res.is_page_larger = Number(pageHeight > (containerHeight * xPercent) / 100)
    }
  }
  return res
}

export const getPageId = () => {
  const pageQuery = (window.location.search || '').substring(1)
  const query = qs.parse(pageQuery) || {}
  return query.pageId || ''
}

export const getPageReportInfo = () => {
  return {
    scroll_top: getViewHeight(),
    container_height: document.documentElement.clientHeight,
    page_height: document.documentElement.scrollHeight
  }
}

export const getPageReportCvrScrollInfo = () => {
  return {
    scroll_height: getScrollHeight(),
    scroll_width: getScrollLeft(),
    web_container_height: document.documentElement.clientHeight,
    web_container_with: document.documentElement.clientWidth,
    scroll_height_rate: Number((getScrollHeight() / document.documentElement.clientHeight).toFixed(2)),
    getScrollLeft
  }
}

export const callJSB = ({
  method,
  data = {},
  callback = () => {},
  onNotSupport = () => {},
  reportResHandler = () => {}
}) => {
  const reportInfo = {
    jsb_name: method
  }
  if (!inBiliApp || !isSupport) {
    onNotSupport && onNotSupport()
  }
  return isSupport(method).then((support) => {
    if (support) {
      Vue.prototype.$reportUI(MINI_jsb_request, { ...reportInfo })
      const now = Date.now()
      biliBridge.callNative({
        method,
        data,
        callback: (res) => {
          Vue.prototype.$reportUI(MINI_jsb_callback, {
            ...reportInfo,
            jsb_code: res.code,
            jsb_time: Date.now() - now,
            jsb_data: reportResHandler(res) || ''
          })
          callback && callback(res)
        }
      })
    } else {
      Vue.prototype.$reportUI(MINI_jsb_not_support, { ...reportInfo })
      onNotSupport && onNotSupport()
    }
  })
}
