import { isAd, isPreview } from '../utils'
import { getQuery } from '@/utils'

export const commonMessage = (eventObj = {}) => {
  const {
    screenX = 0,
    screenY = 0,
    pageX = 0,
    pageY = 0,
    clientX = 0,
    clientY = 0,
    offsetX = 0,
    offsetY = 0
  } = eventObj || {}

  return {
    position_x: pageX,
    position_y: pageY,
    screen_x: screenX,
    screen_y: screenY,
    offset_x: offsetX,
    offset_y: offsetY,
    client_x: clientX,
    client_y: clientY,
    window_w: window.screen.width,
    window_h: window.screen.height,
    control_w: document.documentElement.clientWidth,
    control_h: document.documentElement.clientHeight
  }
}

const report = (event, params, eventObj, options = {}) => {
  if (getQuery('is_gaoneng_preload')) {
    return
  }
  try {
    const ext = {
      ...params,
      ...commonMessage(eventObj),
      ...window?.alita?.deviceInfo,
      is_ad: +isAd()
    }
    const logInfo = { event, component: ext.item_name || '', ext, ts: Date.now() }
    console.log(logInfo)

    const info = {
      event,
      url: window.location.href,
      report: JSON.stringify({ ...ext })
    }
    if (window.ad_cb) {
      info.ad_cb = window.ad_cb
    }
    if (!isPreview()) {
      window.alita.send(info)
    }
  } catch (e) {
    console.log(e)
  }
}

export default (Vue) => {
  Vue.prototype.$reportUI = report
}
