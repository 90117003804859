/* eslint-disable no-case-declarations */
import qs from 'qs'
import { biliBridge, inBiliApp } from '@bilibili/js-bridge'
import { gaonengFormUrl } from '../../config'
import {
  MINI_callup_weixin_game_fail,
  MINI_callup_weixin_game_success,
  MINI_callup_weixin_not_install,
  MINI_item_click,
  MINI_item_cvr_click
} from '../constants/reportEvents'
import {
  WARN_page_weixin_game_fail,
  WARN_callup_weixin_not_install
} from '@/canvas/constants/warnEvents'
import { callJSB, isIOS } from '@/canvas/utils'

const WX_ERROR = {
  201: '微信未安装',
  202: '下发数据不合法',
  203: '微信唤起失败'
}

const ANDROID_SUPPORT_WX_VERSION = 6750000
const IOS_SUPPORT_WX_VERSION = 67500000

export default {
  props: {
    reportInfo: {},
    link: {
      type: Object,
      default: () => {}
    }
  },
  inject: ['pageId', 'pageQuery', 'compName', 'compId'],
  data() {
    return {
      platform: '',
      showFloatForm: {
        show: false
      },
      model: '',
      build: ''
    }
  },
  computed: {
    // schemeEvent, downloadEvent, formEvent, linkEvent, wxEvent
    type() {
      return this.link?.type
    },
    href() {
      const { type, behavior } = this.link || {}
      const { form = {}, formMode } = behavior || {}

      if (!type || !behavior) {
        return ''
      }

      switch (type) {
        case 'linkEvent':
          return this.withTrackId(behavior.url)
        case 'schemeEvent':
          return 'javascript:void(0);'
        case 'downloadEvent':
          // const ua = window.navigator.userAgent || ''
          // const system = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1 ? 'android' : ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ? 'ios' : 'ios'

          return 'javascript:void(0);'
        case 'formEvent':
          if (formMode === 1) return 'javascript:void(0);'

          return `${gaonengFormUrl}?formId=${behavior.formId}&title=${encodeURIComponent(
            form.title || ''
          )}&msg=${encodeURIComponent(form.successMessage || '')}&${this.pageQuery || ''}&compId=${
            this.compId
          }&is_show_distance_lbs=${window._globalData?.isDownloadIllegal || '0'}&use_ip_location=${
            window._globalData?.useIpLocation || '0'
          }`

        case 'wxEvent':
          return 'javascript:void(0);'
      }
    }
  },
  methods: {
    // 唤起类型，同时唤起和跳转
    onClick(ev) {
      const { type, behavior = {} } = this.link || {}

      this.$reportUI(
        MINI_item_click,
        {
          item_name: this.compName,
          item_id: this.compId,
          event_type: type,
          ...this.reportInfo,
          ...(type === 'formEvent' ? { form_mode: behavior.formMode || 0 } : {})
        },
        ev
      )
      this.$reportUI(
        MINI_item_cvr_click,
        {
          event_type: type,
          ...this.reportInfo
        },
        ev
      )
      if (type === 'formEvent' && behavior.formMode === 1) {
        this.showFloatForm.show = true
      }

      if (type === 'wxEvent') {
        return this.wxEventHandler(behavior.wxData)
      }

      if (type === 'schemeEvent') {
        return this.schemeJumpHandler(behavior)
      }
      if (type === 'downloadEvent') {
        const behaviorItem = behavior[this.platform] || {}
        return this.schemeJumpHandler(behaviorItem)
      }

      this.jumpToHref(this.href)
    },
    jumpToHref(url) {
      /**
       * 兼容部分设备无法跳转
       * */
      if (url) {
        document.location.href = url
      }
      if (url && !isIOS) {
        setTimeout(() => {
          document.location.href = url
        }, 0)
      }
    },
    schemeJumpHandler(data) {
      if (inBiliApp && data.scheme && data.url) {
        return callJSB({
          method: 'cm.callUpApp',
          data: {
            schemaUrl: this.withTrackId(data.scheme),
            jumpLink: this.withTrackId(data.url)
          },
          callback: (res) => {
            if (res.status !== 1) {
              this.jumpToHref(this.withTrackId(data.url))
            }
          },
          onNotSupport: () => {
            this.jumpToHref(this.withTrackId(data.url))
          }
        })
      }
      this.jumpToHref(this.withTrackId(data.scheme || data.url))
    },
    getModel() {
      if (window.alita) {
        window.alita.getNativeInfo().then((res) => {
          this.model = res.model
          this.build = res.build_id
        })
      }
    },
    wxEventHandler(data) {
      const supportAndroid = this.model === 'android' && this.build >= ANDROID_SUPPORT_WX_VERSION
      const supportIOS = this.model === 'iphone' && this.build >= IOS_SUPPORT_WX_VERSION
      const self = this

      const downgrade = () => {
        if (!data.url) return
        // 失败跳降级URL
        const downgradeUrl = self.replaceUrl(data.url)
        document.location.href = downgradeUrl
        setTimeout(() => {
          document.location.href = downgradeUrl
        }, 0)
      }

      if (inBiliApp && (supportAndroid || supportIOS)) {
        biliBridge.callNative({
          method: 'cm.callupWxMini',
          data: {
            wx_program_info: {
              org_id: data.orgId,
              name: data.name,
              path: self.replaceUrl(data.path)
            }
          },
          callback: function (res) {
            if (res.code === 0) {
              self.$reportUI(MINI_callup_weixin_game_success)
            } else {
              self.$reportUI(MINI_callup_weixin_game_fail, { reason: WX_ERROR[res.code] })
              this.$reportWarn(WARN_page_weixin_game_fail, { reason: WX_ERROR[res.code] })
              if (res.code === 201) {
                self.$reportUI(MINI_callup_weixin_not_install)
                this.$reportWarn(WARN_callup_weixin_not_install, { reason: WX_ERROR[res.code] })
              }
              if (data.url) {
                downgrade()
              } else {
                self.$toast(WX_ERROR[res.code])
              }
            }
          }
        })
      } else {
        downgrade()
      }
    },
    replaceUrl(url) {
      if (!url) return ''

      const replaceMap = {
        __TRACKID__: 'track_id',
        __CREATIVEID__: 'creative_id',
        __BUVID__: 'buvid',
        __MID__: 'mid',
        __IMEI__: 'imei',
        __DUID__: 'duid',
        __IDFA__: 'idfa',
        __ANDROIDID__: 'android_id',
        __OS__: 'os',
        __REQUESTID__: 'request_id',
        __SOURCEID__: 'source_id',
        __ADTYPE__: 'adtype'
      }

      const search = (window.location.search || '').slice(1)
      Object.keys(replaceMap).forEach((key) => {
        const field = replaceMap[key]
        search.replace(`${field}=${key}`, `${field}=`)
      })

      const query = qs.parse(search) || {}
      let resUrl = url
      Object.keys(replaceMap).forEach((key) => {
        const field = replaceMap[key]
        if (resUrl.includes(key) && query[field]) {
          resUrl = resUrl.replace(key, query[field])
        }
      })

      return resUrl
    },
    withTrackId(url) {
      const search = (window.location.search || '')
        .substr(1)
        .replace('track_id=__TRACKID__', 'track_id=')
      if (!url) {
        return url
      }
      if (search === '') {
        return url
      }

      const query = qs.parse(search) || {}
      const trackId = query.track_id || ''

      if (!trackId) return url

      // 有宏，则将宏无脑替换成实际trackId
      if (url.includes('__TRACKID__')) {
        return url.replace('__TRACKID__', trackId)
      }

      return url
    }
  },
  created() {
    this.getModel()
  },
  mounted() {
    const ua = window.navigator.userAgent || ''
    const platform =
      ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1
        ? 'android'
        : ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        ? 'ios'
        : 'web'

    this.platform = platform
  }
}
