import { getEleTop, getFirstScreen } from '../../utils'
// eslint-disable-next-line camelcase
import { MINI_item_img_load_finish } from '../../constants/reportEvents'

export default {
  inject: ['compId', 'compName'],
  methods: {
    imageLoadHandler() {
      const start = Date.now()
      // eslint-disable-next-line no-undef
      const imageRef = this.$refs.image
      if (!imageRef) return

      imageRef.onload = () => {
        const end = Date.now()
        this.$reportUI(MINI_item_img_load_finish, {
          item_id: this.compId,
          item_name: this.compName,
          load_time: end - start,
          top: getEleTop(imageRef),
          first_screen: Number(getFirstScreen(imageRef)),
          img_source: this.image
        })
      }
    }
  },
  mounted() {
    this.imageLoadHandler()
  }
}
