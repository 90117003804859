import iconButton from '../assets/icon-c-button.svg'
import iconDownload from '../assets/icon-c-download.svg'
import iconForm from '../assets/icon-c-form.svg'
import iconGoods from '../assets/icon-c-goods.svg'
import iconImage from '../assets/icon-c-image.svg'
import iconText from '../assets/icon-c-text.svg'
import iconVideo from '../assets/icon-c-video.svg'
import iconWechat from '../assets/wechat.svg'

const styles = {
  // 这两个特殊，为了保持相同逻辑处理block
  block: {
    component: 'Block',
    style: 'default'
  },
  'fixed-block': {
    component: 'FixedBlock',
    style: 'default'
  },
  // key代表编辑器中可选的样式 styleId
  'rectangle-default': {
    component: 'Rectangle', // 使用哪个组件实现 canvas/*
    style: '', // 用哪套数据 styles.json 中的key 或者不写 代表 schema.json中的default
    styleName: '', // 非必须，目前编辑器UI中无展现
    componentName: '方块', // 在编辑器中这个样式所属的组件名
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/0tKsbr2k4Q.png', // 图例，用于左侧样式选择
    icon: iconButton,
    version: 0x011111
  },
  'shape-rectangle': {
    component: 'ShapeRectangle',
    style: '',
    styleName: '',
    componentName: '方形',
    image: 'https://i0.hdslb.com/bfs/sycp/mgk/collage/png/202310/74d6b52e1f7ba6606c99e93d2ddda537.png'
  },
  'rectangle-big': {
    component: 'Rectangle',
    style: 'big',
    styleName: '',
    componentName: '方块',
    noHeader: true,
    icon: iconForm,
    movable: false,
    version: (data) => {
      // 根据data判断版本
      return 0x011111
    }
  },
  'rectangle-small': {
    component: 'Rectangle',
    style: 'small',
    styleName: '',
    componentName: '方块',
    icon: iconDownload
  },
  'rectangle-left': {
    component: 'Rectangle',
    style: 'left',
    styleName: '',
    componentName: '方块',
    icon: iconImage
  },
  'rectangle-right': {
    component: 'Rectangle',
    style: 'right',
    styleName: '',
    componentName: '方块',
    icon: iconImage
  },
  'plain-video': {
    component: 'Video',
    style: 'normal',
    componentName: '常规视频',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210427/350f34a338378c33628aa40ad0c3ae8e/0sHZEgFe3L.png',
    icon: iconVideo
  },
  'full-video': {
    component: 'VideoFullScreen',
    style: 'fullscreen',
    componentName: '全屏视频',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210427/350f34a338378c33628aa40ad0c3ae8e/Lvqc8Y4ggV.png',
    icon: iconVideo,
    movable: false
  },
  'top-landscape-video': {
    component: 'VideoFixed',
    style: 'landscape',
    componentName: '吸顶视频-横屏',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210427/350f34a338378c33628aa40ad0c3ae8e/0sHZEgFe3L.png',
    icon: iconVideo,
    noHeader: true
  },
  'joint-video-fixed-top': {
    component: 'VideoFixed',
    style: 'landscape',
    componentName: '创意联投-视频-横屏',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210427/350f34a338378c33628aa40ad0c3ae8e/0sHZEgFe3L.png',
    icon: iconVideo,
    noHeader: true
  },
  'top-portrait-video': {
    component: 'VideoFixed',
    style: 'portrait',
    componentName: '吸顶视频-竖屏',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210427/350f34a338378c33628aa40ad0c3ae8e/SvPEZMiPhm.png',
    icon: iconVideo,
    noHeader: true
  },
  'image-normal': {
    component: 'Image',
    style: 'image-normal',
    componentName: '图片',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/hX1ssFqSlH.png',
    icon: iconImage
  },
  'image-two': {
    component: 'ImageMulti',
    style: 'image-two',
    componentName: '图片',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/fQrCamddRV.png',
    icon: iconImage
  },
  'image-three': {
    component: 'ImageMulti',
    style: 'image-three',
    componentName: '图片',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/qQnTMrdBfJ.png',
    icon: iconImage
  },
  'image-carousel-normal': {
    component: 'ImageCarouselNormal',
    style: 'image-carousel-normal',
    componentName: '图片',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/MYxRCaMn8e.png',
    icon: iconImage
  },
  'image-carousel-card': {
    component: 'ImageCarouselCard',
    style: 'image-carousel-card',
    componentName: '图片',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/iA73k21lb6.png',
    icon: iconImage
  },
  'image-carousel-translate': {
    component: 'ImageCarouselTranslate',
    style: 'image-carousel-translate',
    componentName: '图片',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/VItdcUofhF.png',
    icon: iconImage
  },
  'plain-text-normal': {
    component: 'PlainText',
    style: 'normal',
    componentName: '简单文本',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/RKS0FRmqZH.png',
    icon: iconText
  },
  'plain-text-songti': {
    component: 'PlainText',
    style: 'songti',
    componentName: '简单文本',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/RKS0FRmqZH.png',
    icon: iconText
  },
  'plain-text-kaiti': {
    component: 'PlainText',
    style: 'kaiti',
    componentName: '简单文本',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/kEFxLWpGb7.png',
    icon: iconText
  },
  'plain-text-yahei': {
    component: 'PlainText',
    style: 'yahei',
    componentName: '简单文本',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/xvPP6p8HD3.png',
    icon: iconText
  },
  'plain-text-pingfang': {
    component: 'PlainText',
    style: 'pingfang',
    componentName: '简单文本',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/VzzL3VdBuu.png',
    icon: iconText
  },
  'rich-text-normal': {
    component: 'RichText',
    style: 'normal',
    componentName: '富文本',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/RKS0FRmqZH.png'
  },
  /* 'rich-text-songti': {
    component: 'RichText',
    style: 'songti',
    componentName: '富文本',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/RKS0FRmqZH.png'
  },
  'rich-text-kaiti': {
    component: 'RichText',
    style: 'kaiti',
    componentName: '富文本',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/kEFxLWpGb7.png'
  },
  'rich-text-yahei': {
    component: 'RichText',
    style: 'yahei',
    componentName: '富文本',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/xvPP6p8HD3.png'
  },
  'rich-text-pingfang': {
    component: 'RichText',
    style: 'pingfang',
    componentName: '富文本',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/VzzL3VdBuu.png'
  }, */
  'button-large': {
    component: 'Button',
    style: 'large',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211129/350f34a338378c33628aa40ad0c3ae8e/S003spL57J.png',
    icon: iconButton
  },
  'button-small': {
    component: 'Button',
    style: 'small',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/IITBLjShTh.png',
    icon: iconButton
  },
  'button-image': {
    component: 'ButtonImage',
    style: 'button-image-normal',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/0tKsbr2k4Q.png',
    icon: iconButton
  },
  'button-large-fixed-top': {
    component: 'Button',
    style: 'large-fixed-top',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211129/350f34a338378c33628aa40ad0c3ae8e/S003spL57J.png',
    icon: iconButton
  },
  'button-small-fixed-top': {
    component: 'Button',
    style: 'small-fixed-top',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/IITBLjShTh.png',
    icon: iconButton
  },
  'button-image-fixed-top': {
    component: 'ButtonImage',
    style: 'button-image-fixed-top',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/0tKsbr2k4Q.png',
    icon: iconButton
  },
  'button-large-fixed-bottom': {
    component: 'Button',
    style: 'large-fixed-bottom',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211129/350f34a338378c33628aa40ad0c3ae8e/S003spL57J.png',
    icon: iconButton
  },
  'button-small-fixed-bottom': {
    component: 'Button',
    style: 'small-fixed-bottom',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/IITBLjShTh.png',
    icon: iconButton
  },
  'button-image-fixed-bottom': {
    component: 'ButtonImage',
    style: 'button-image-fixed-bottom',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/0tKsbr2k4Q.png',
    icon: iconButton
  },
  'button-download-bg-normal': {
    component: 'ButtonDownloadBg',
    style: 'normal',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/a0iFqgi3Xn.png',
    icon: iconButton
  },
  'button-download-bg-fixed-bottom': {
    component: 'ButtonDownloadBg',
    style: 'fixed-bottom',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/a0iFqgi3Xn.png',
    icon: iconButton
  },
  'button-download-bg-fixed-top': {
    component: 'ButtonDownloadBg',
    style: 'fixed-top',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/a0iFqgi3Xn.png',
    icon: iconButton
  },
  'button-download-large': {
    component: 'DownloadButton',
    style: 'download-large',
    componentName: '按钮下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211129/350f34a338378c33628aa40ad0c3ae8e/IcAPyWgc61.png',
    icon: iconDownload
  },
  'gameapp-button-download-large': {
    component: 'DownloadButton',
    style: 'gameapp-download-large',
    componentName: '按钮下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211129/350f34a338378c33628aa40ad0c3ae8e/IcAPyWgc61.png',
    icon: iconDownload,
    version: 0x100100
  },
  'gameapp-button-download-large-fixed-top': {
    component: 'DownloadButton',
    style: 'gameapp-download-large-fixed-top',
    componentName: '按钮下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211129/350f34a338378c33628aa40ad0c3ae8e/IcAPyWgc61.png',
    icon: iconDownload,
    version: 0x100100
  },
  'gameapp-button-download-large-fixed-bottom': {
    component: 'DownloadButton',
    style: 'gameapp-download-large-fixed-bottom',
    componentName: '按钮下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211129/350f34a338378c33628aa40ad0c3ae8e/IcAPyWgc61.png',
    icon: iconDownload,
    version: 0x100100
  },
  'button-download-small': {
    component: 'DownloadButton',
    style: 'download-small',
    componentName: '按钮下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/9FwWww1ZX8.png',
    icon: iconDownload
  },
  'button-download-image': {
    component: 'Image',
    style: 'download-image-button',
    componentName: '图片下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/0tKsbr2k4Q.png',
    icon: iconDownload
  },
  'button-download-large-fixed-top': {
    component: 'DownloadButton',
    style: 'download-large-fixed-top',
    componentName: '按钮下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211129/350f34a338378c33628aa40ad0c3ae8e/IcAPyWgc61.png',
    icon: iconDownload
  },
  'button-download-small-fixed-top': {
    component: 'DownloadButton',
    style: 'download-small-fixed-top',
    componentName: '按钮下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/9FwWww1ZX8.png',
    icon: iconDownload
  },
  'button-download-image-fixed-top': {
    component: 'Image',
    style: 'download-image-fixed-top',
    componentName: '图片下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/0tKsbr2k4Q.png',
    icon: iconDownload
  },
  'button-download-large-fixed-bottom': {
    component: 'DownloadButton',
    style: 'download-large-fixed-bottom',
    componentName: '按钮下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211129/350f34a338378c33628aa40ad0c3ae8e/IcAPyWgc61.png',
    icon: iconDownload
  },
  'button-download-small-fixed-bottom': {
    component: 'DownloadButton',
    style: 'download-small-fixed-bottom',
    componentName: '按钮下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/9FwWww1ZX8.png',
    icon: iconDownload
  },
  'button-download-image-fixed-bottom': {
    component: 'Image',
    style: 'download-image-fixed-bottom',
    componentName: '图片下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/0tKsbr2k4Q.png',
    icon: iconDownload
  },
  'logo-button-normal': {
    component: 'LogoButton',
    style: 'normal',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/0nzJrq6cWD.png',
    icon: iconDownload
  },
  'logo-button-fixed-top': {
    component: 'LogoButton',
    style: 'fixed-top',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/0nzJrq6cWD.png',
    icon: iconDownload
  },
  'logo-button-fixed-bottom': {
    component: 'LogoButton',
    style: 'fixed-bottom',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/0nzJrq6cWD.png',
    icon: iconDownload
  },
  'simple-logo-button-normal': {
    component: 'SimpleLogoButton',
    style: 'normal',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/AkGrjniO1b.png',
    icon: iconDownload
  },
  'simple-logo-button-fixed-top': {
    component: 'SimpleLogoButton',
    style: 'fixed-top',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/AkGrjniO1b.png',
    icon: iconDownload
  },
  'simple-logo-button-fixed-bottom': {
    component: 'SimpleLogoButton',
    style: 'fixed-bottom',
    componentName: '按钮',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/AkGrjniO1b.png',
    icon: iconDownload
  },
  'download-normal': {
    component: 'Download',
    style: 'normal',
    componentName: '应用下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/AKhA1SZFfo.png',
    icon: iconDownload
  },
  'download-fixed-top': {
    component: 'Download',
    style: 'fixed-top',
    componentName: '应用下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/AKhA1SZFfo.png',
    icon: iconDownload
  },
  'download-fixed-bottom': {
    component: 'Download',
    style: 'fixed-bottom',
    componentName: '应用下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/AKhA1SZFfo.png',
    icon: iconDownload
  },
  'simple-download-normal': {
    component: 'SimpleDownload',
    style: 'normal',
    componentName: '应用下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/b95AOyz5OC.png',
    icon: iconDownload
  },
  'simple-download-fixed-top': {
    component: 'SimpleDownload',
    style: 'fixed-top',
    componentName: '应用下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/b95AOyz5OC.png',
    icon: iconDownload
  },
  'simple-download-fixed-bottom': {
    component: 'SimpleDownload',
    style: 'fixed-bottom',
    componentName: '应用下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/b95AOyz5OC.png',
    icon: iconDownload
  },
  'download-bg-normal': {
    component: 'DownloadBg',
    style: 'normal',
    componentName: '应用下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/DAlQWVc8yD.png',
    icon: iconDownload
  },
  'download-bg-fixed-top': {
    component: 'DownloadBg',
    style: 'fixed-top',
    componentName: '应用下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/DAlQWVc8yD.png',
    icon: iconDownload
  },
  'download-bg-fixed-bottom': {
    component: 'DownloadBg',
    style: 'fixed-bottom',
    componentName: '应用下载',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20211118/350f34a338378c33628aa40ad0c3ae8e/DAlQWVc8yD.png',
    icon: iconDownload
  },
  'form-normal': {
    component: 'Form',
    style: 'normal',
    componentName: '表单',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/plGR6wgXSW.png',
    placeholder:
      'https://i0.hdslb.com/bfs/activity-plat/static/20210407/350f34a338378c33628aa40ad0c3ae8e/tlXPu5cZKA.png',
    icon: iconForm
  },
  'form-simple': {
    component: 'FormSimple',
    style: 'simple',
    componentName: '表单',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210322/4fd3833722492cf2d9e284c3722f6329/xTBj75W6Lu.png',
    placeholder:
      'https://i0.hdslb.com/bfs/activity-plat/static/20210407/350f34a338378c33628aa40ad0c3ae8e/yuSW3H1k0w.png',
    icon: iconForm
  },
  'form-question': {
    component: 'Form',
    style: 'question',
    componentName: '表单',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210527/d72cd055e5397bb5587804649580b870/9KxnTWnwQP.png',
    placeholder:
      'https://i0.hdslb.com/bfs/activity-plat/static/20210527/d72cd055e5397bb5587804649580b870/1LVVIckidy.png',
    icon: iconForm
  },
  'weixin-button-large': {
    component: 'WeixinButtonLarge',
    style: 'normal',
    componentName: '微信',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20220613/350f34a338378c33628aa40ad0c3ae8e/x37O8BLxrr.png',
    icon: iconWechat
  },
  'weixin-button-large-fixed-top': {
    component: 'WeixinButtonLarge',
    style: 'fixed-top',
    componentName: '微信',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20220613/350f34a338378c33628aa40ad0c3ae8e/x37O8BLxrr.png',
    icon: iconWechat
  },
  'weixin-button-large-fixed-bottom': {
    component: 'WeixinButtonLarge',
    style: 'fixed-bottom',
    componentName: '微信',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20220613/350f34a338378c33628aa40ad0c3ae8e/x37O8BLxrr.png',
    icon: iconWechat
  },
  'weixin-button-detail': {
    component: 'Weixin',
    style: 'normal',
    componentName: '微信',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20220613/350f34a338378c33628aa40ad0c3ae8e/gqQd7odmlv.png',
    icon: iconWechat
  },
  'weixin-button-detail-fixed-top': {
    component: 'Weixin',
    style: 'fixed-top',
    componentName: '微信',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20220613/350f34a338378c33628aa40ad0c3ae8e/gqQd7odmlv.png',
    icon: iconWechat
  },
  'weixin-button-detail-fixed-bottom': {
    component: 'Weixin',
    style: 'fixed-bottom',
    componentName: '微信',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20220613/350f34a338378c33628aa40ad0c3ae8e/gqQd7odmlv.png',
    icon: iconWechat
  },
  'weixin-button-small-fixed-bottom': {
    component: 'WeixinButtonSmall',
    style: 'fixed-bottom',
    componentName: '微信',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20220613/350f34a338378c33628aa40ad0c3ae8e/n7aSKVMS5v.png',
    icon: iconWechat
  },
  'goods-double': {
    component: 'Goods',
    style: 'double',
    componentName: '商品目录',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210917/350f34a338378c33628aa40ad0c3ae8e/49zN3gkOXp.png',
    icon: iconGoods
  },
  'goods-single': {
    component: 'Goods',
    style: 'single',
    componentName: '商品目录',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210917/350f34a338378c33628aa40ad0c3ae8e/uzAscTnJiv.png',
    icon: iconGoods
  },
  'goods-double-main': {
    component: 'GoodsMain',
    style: 'double',
    componentName: '商品目录',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210917/350f34a338378c33628aa40ad0c3ae8e/iY02hzBLkp.png',
    icon: iconGoods
  },
  'goods-single-main': {
    component: 'GoodsMain',
    style: 'single',
    componentName: '商品目录',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210917/350f34a338378c33628aa40ad0c3ae8e/jb6R4O5aEl.png',
    icon: iconGoods
  },
  'template-introduce': {
    component: 'Introduce',
    style: 'single',
    componentName: '产品介绍',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210917/350f34a338378c33628aa40ad0c3ae8e/jb6R4O5aEl.png',
    icon: iconGoods
  },
  'template-form': {
    component: 'TemplateForm',
    style: 'single',
    componentName: '交易表单',
    image: 'https://i0.hdslb.com/bfs/activity-plat/static/20210917/350f34a338378c33628aa40ad0c3ae8e/jb6R4O5aEl.png',
    icon: iconGoods
  }
}

export default styles
