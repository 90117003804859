<template>
  <base-popup v-model="show" position="bottom">
    <van-picker
      :columns="columns"
      show-toolbar
      :default-index="defaultIndex"
      :item-height="itemHeight"
      :visible-item-count="visibleItemCount"
      :swipe-duration="swipeDuration"
      @confirm="onConfirm"
      @cancel="onCancel"
    ></van-picker>
  </base-popup>
</template>
<script>
import mixin from './mixin'

export default {
  name: 'PopupPickerSingleCol',
  mixins: [mixin],
  props: {
    textField: {
      type: String,
      default: 'name'
    },
    idField: {
      type: String,
      default: 'id'
    },
    childrenField: {
      type: String,
      default: 'children'
    },
    defaultIndex: {
      type: Number,
      default: 0
    },
    swipeDuration: {
      type: Number,
      default: 800
    }
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
    },
    onCancel() {
      this.close()
      this.$emit('cancel')
    },
    onConfirm(value, idx) {
      this.close()
      this.$emit('confirm', { name: value, idx })
    }
  }
}
</script>

<style scoped lang="less">
.van-popup {
  width: 375px;
  margin: 0 auto;
  left: 0;
  right: 0;
  border-radius: 0 !important;
}
</style>
