export default {
  props: ['item', 'options', 'priorityDisplay'],
  inject: ['context'],
  watch: {
    'options.themeColor'(newValue, oldValue) {
      this.onThemeColorChange(newValue)
    }
  },
  computed: {
    showButton() {
      // 下载转化只能使用按钮
      return true
      // return this.options.downloadButton?.showButton
    },
    showPrice() {
      return this.options?.priorityDisplay === 0
    },
    showDesc() {
      return this.options?.priorityDisplay === 1
    },
    buttonStyle() {
      const { borderWidth, borderRadius, backgroundImage, fontSize, ...rest } =
        this.options?.downloadButton?.buttonStyle || {}
      return {
        borderRadius: borderRadius + 'px',
        borderWidth: borderWidth + 'px',
        fontSize: fontSize + 'px',
        text: this.options.buttonText,
        ...rest
      }
    },
    priceStyle() {
      return { color: this.options?.themeColor }
    },
    link() {
      return this.options?.app?.link
    }
  },
  methods: {
    onThemeColorChange(color) {
      if (this.context !== 'editor') {
        return
      }

      this.options.downloadButton.buttonStyle.borderColor = color
      this.options.downloadButton.buttonStyle.backgroundColor = color
    }
  }
}
