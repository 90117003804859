import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import fakeData from './fakeData'

export default {
  components: { Swiper, SwiperSlide },
  watch: {
    list(newValue, oldValue) {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      if (this.list && this.list.length) {
        this.finalList = [...this.list]
      } else {
        this.finalList = [...fakeData]
      }
    }
  }
}
