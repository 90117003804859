<template>
  <base-popup v-model="visible" :max-zoom="1.7" position="bottom" has-header modal-style>
    <template v-if="visible">
      <popup-header @close="close">授权书</popup-header>
      <div class="content-wrapper">
        <div class="content">
          <!--        <div class="title">授权书</div>-->
          <div class="bold">
            上海宽娱数码科技有限公司（哔哩哔哩平台的运营者，以下简称“哔哩哔哩”）、幻电科技（上海）有限公司（广告表单页建站技术服务的提供方，以下简称“高能建站”）非常重视用户信息的保护。“哔哩哔哩”与“高能建站”以下合称为“我们”。我们希望通过本授权书向您说明手机号/地理位置/历史信息填充服务中我们如何共享、收集、使用、保护您的信息，以进一步保障您的信息安全和为您提供更便捷的服务。请您务必仔细阅读本授权书。您一旦主动在相应页面进行授权，即意味着您同意我们按照本授权书共享、收集、使用您的姓名、手机号、地理位置信息及其他您在页面主动提供的信息。
          </div>
          <div class="sub-title">一、个人信息的共享、收集与使用</div>
          <br />
          <div>
            1、您在使用登录手机号自动填充功能时，哔哩哔哩会在您每次进入表单页面时向高能建站提供您当前的登录手机号，用于高能建站将其自动填写到表单中。
          </div>
          <br />
          <div>
            2、您在使用地理位置自动填充功能时，哔哩哔哩会在您每次进入表单页面时向高能建站提供您当前的精确地理位置信息，用于高能建站将其自动填写到表单中，匹配表单中的相关地理位置信息供您选择。
          </div>
          <br />
          <div>
            3、您在使用历史信息自动填充功能时，高能建站会收集您的姓名、手机号及其他您在页面主动提供的信息，在您下次进入表单页面时，高能建站会帮您自动填写您上次输入的历史信息。
          </div>
          <br />
          <div>
            4、您需要注意的是，以上个人信息均是您自愿提供。如您不愿意提供，可以不授权或取消授权相关功能，我们将不再共享、收集、使用与该功能相关的个人信息。
          </div>
          <br />
          <div>
            5、您可以手动修改以上自动填写的信息。在您点击按钮提交之前，高能建站不会存储或对外传输当前自动填写或您手动修改后的信息。
          </div>
          <br />
          <div>
            6、高能建站致力于为您提供安全、可信的产品与使用环境，提供优质而可靠的服务与信息是高能建站的核心目标。为了维护高能建站服务的正常运行，保护您或其他用户或公众的合法利益免受损失，高能建站会收集用于维护产品或服务安全稳定运行的必要信息（例如您使用本服务的相关日志信息）。
          </div>
          <br />
          <div>7、高能建站为满足您的需求，可能通过您提供的信息与您联系。</div>
          <br />
          <div>
            8、高能建站可能自行或委托、安排哔哩哔哩或其他第三方定期或不定期向您发送有关产品、服务或相关活动的信息，但请你放心，如你不愿接受这些信息，你可以通过信息中的退订方式进行退订，也可以直接与高能建站联系进行退订。
          </div>
          <br />
          <div>
            9、如您拟撤回授权/删除您提交的个人信息的，您可以取消授权登录手机号/历史信息/地理位置的自动填写功能，高能建站将不再继续自动填写并删除您的个人信息。
          </div>
          <br />
          <div>
            10、您应对您提供的信息的真实性、合法性、完整性、准确性和有效性承担责任。如您具有合理且必要的理由需使用其他主体的信息，您应确保已依法取得相关权利人的有效授权。如权利人发现您无权使用该等信息，或者对您向我们提供该等信息有任何疑问或意见，建议权利人优先联系您处理。如您违反前述约定，您应自行承担因此产生的全部责任。
          </div>
          <div class="sub-title">二、个人信息的保护与存储</div>
          <br />
          <div>
            1、请您知悉，您提交的部分信息属于敏感个人信息（如：手机号码），一旦泄露或被非法使用，可能会对您或相关个人信息主体的人身、财产安全、个人隐私等造成损害，请您在确保安全的情况下提供。我们将严格保护您的个人信息安全。我们将采用适当制度、安全技术和程序等措施来保护您的个人信息不被未经授权的访问、使用或泄漏。
          </div>
          <br />
          <div>
            2、高能建站将在中华人民共和国境内、在实现上述目的所需的期限内存储您的信息，在超出存储期限后，高能建站将会根据适用法律法规的要求删除您的信息或匿名化处理，除非适用法律法规另有规定或高能建站依照适用法律法规另行取得您的授权。
          </div>
          <div class="sub-title">三、未成年人信息的特殊保护</div>
          <br />
          <div>
            1、若您是未满18周岁的未成年人，在使用本功能前，应在您的父母或其他监护人监护、指导下共同阅读并同意本授权书。
          </div>
          <br />
          <div>
            2、若您是未满14周岁的未成年人的监护人，在使用本功能前，应为您的被监护人阅读并同意本授权书。
          </div>
          <div class="sub-title">四、适用法律与争议解决</div>
          <br />
          <div>
            本授权书适用中华人民共和国大陆地区法律。任何因本授权书以及我们处理您个人信息事宜引起的争议，您同意向上海仲裁委员会提起仲裁解决；该仲裁一裁终局，对双方均具有法律约束力。
          </div>
          <div class="sub-title">五、变更</div>
          <br />
          <div>
            我们会适时对本授权书进行修订，该等修订构成本授权书的一部分。我们会将修订后的授权书发布在表单页面。在该种情况下，若您继续使用我们的服务，即表示同意接受经修订的授权书的约束。
          </div>
        </div>
        <div class="button-area">
          <div class="button" @click="close">我已阅读</div>
        </div>
      </div>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from '../../base/BasePopup'
import PopupHeader from '../../base/PopupHeader'

export default {
  name: 'AgreementLocation',
  components: { BasePopup, PopupHeader },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
      this.$emit('read')
    }
  }
}
</script>

<style lang="less" scoped>
.content-wrapper {
  width: 100%;
  height: 393px;
  padding-top: 0;
  border-radius: 10px;
  .content {
    height: 306px;
    overflow-y: auto;
    padding: 16px 16px 0;
    color: #666666;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    .title {
      font-size: 18px;
      line-height: 25px;
      color: #000000;
      margin-bottom: 20px;
    }
    .sub-title {
      margin-top: 24px;
      font-size: 14px;
      line-height: 24px;
      font-weight: bold;
      color: #333333;
    }
    .paragraph {
      margin-top: 24px;
      margin-bottom: 26px;
    }
    .url {
      color: #0a8afa;
    }
    .center {
      text-align: center;
    }
    .bold {
      font-weight: bold;
      color: #313332;
    }
  }
  .agreement {
    cursor: pointer;
    color: #0a8afa;
  }
  .button-area {
    width: 100%;
    height: 68px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    .button {
      width: 343px;
      height: 44px;
      font-size: 16px;
      line-height: 44px;
      color: white;
      text-align: center;
      cursor: pointer;
      background: rgba(255, 102, 153, 1);
      border-radius: 22px;
    }
  }
}
</style>
