<template>
  <CcGoods :component="component" :context="context" :show-main="true" />
</template>

<script>
import CcGoods from '../components/goods/Goods'

export default {
  name: 'GoodsMain',
  components: { CcGoods },
  props: {
    component: {
      type: Object,
      default: null
    },
    context: {
      type: String,
      default: ''
    }
  }
}
</script>
