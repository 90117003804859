<template>
  <div>
    <div class="cc-video-float cc-float-end-card">
      <slot name="app" class="app-box">
        <img v-if="app.logo" class="app-logo" :src="app.logo" />
        <div v-else class="app-logo empty" />
        <div class="app-content">
          <div class="app-name ellipsis">{{ app.title || '应用名称' }}</div>
          <div v-if="size !== 'small'" class="app-desc">
            {{ app.description || '应用详情' }}
          </div>
        </div>
      </slot>
      <slot name="form">
        <div class="app-button">
          <ButtonComp v-if="button" :content="button" :style="buttonStyle" :download="download" />
        </div>
      </slot>
      <slot name="button" />
    </div>
  </div>
</template>
<script>
import ButtonComp from '../Button'

export default {
  name: 'CcFloatEndCard',
  components: {
    ButtonComp
  },
  props: {
    app: {
      type: Object,
      default: null
    },
    button: {
      type: Object,
      default: () => {}
    },
    buttonStyle: {
      type: Object,
      default: null
    },
    size: {
      type: String,
      default: ''
    },
    download: {
      type: Object,
      default: null
    }
  }
}
</script>
<style lang="less" scoped></style>
