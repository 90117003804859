import { render, staticRenderFns } from "./Link.vue?vue&type=template&id=ff8bd348&scoped=true"
import script from "./Link.vue?vue&type=script&lang=js"
export * from "./Link.vue?vue&type=script&lang=js"
import style0 from "./Link.vue?vue&type=style&index=0&id=ff8bd348&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff8bd348",
  null
  
)

export default component.exports