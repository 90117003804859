<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="closeOnClickOverlay"
    :get-container="getContainer"
    :style="style"
    :position="position"
    :closeable="closeable"
    :class="[`${position === 'bottom' ? 'bottom' : ''}`, { 'bottom-style': modalStyle }]"
    @click-overlay="$emit('click-overlay')"
  >
    <div :class="`${position === 'bottom' ? 'ios-safe' : ''}`">
      <slot></slot>
    </div>
  </van-popup>
</template>

<script>
import { getAppZoom } from '@/canvas/utils'
import { callJSB } from '@/canvas/utils'
import { usePopCount } from './count'
const { getPopCount, addPopCount, reducePopCount } = usePopCount()
export default {
  name: 'BasePopup',
  inject: {
    isFloat: {
      from: 'isFloat', // 祖先组件中提供的数据的键
      default: false // 默认值
    }
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    closeOnClickOverlay: {
      type: Boolean,
      default: false
    },
    getContainer: {
      type: String,
      default: 'body'
    },
    position: {
      type: String,
      default: 'center'
    },
    modalStyle: {
      type: Boolean,
      default: false
    },
    closeable: {
      type: Boolean,
      default: false
    },
    maxZoom: {
      type: Number,
      default: 2
    },
    overrideStyle: {
      type: Object,
      default: () => ({})
    },
    hasHeader: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      style: {
        ...this.overrideStyle,
        zoom: Math.min(getAppZoom(), this.maxZoom)
      }
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    visible() {
      this.show = this.visible
      if (this.visible) {
        addPopCount()
        callJSB({
          method: 'cm.controlPanelBehavior',
          data: { draggable: false }
        })
      } else {
        reducePopCount()
        if (!getPopCount()) {
          callJSB({
            method: 'cm.controlPanelBehavior',
            data: { draggable: true }
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.van-popup {
  border-radius: 10px !important;
  &.bottom {
    width: 375px !important;
    margin: 0 auto !important;
    left: 0 !important;
    right: 0 !important;
  }
  &.bottom-style {
    border-radius: 10px 10px 0 0 !important;
  }
  .popup-header {
    width: 100%;
    height: 53px;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
