import qs from 'qs'

export const getCookie = (name) => {
  if (document.cookie.length > 0) {
    let start = document.cookie.indexOf(name + '=')
    if (start !== -1) {
      start = start + name.length + 1
      let end = document.cookie.indexOf(';', start)
      if (end === -1) end = document.cookie.length
      return unescape(document.cookie.substring(start, end))
    }
  }
  return ''
}

export const getQuery = (key) => {
  const { search } = window.location
  const queries = qs.parse(search.substring(1)) || {}
  return queries[key]
}

export const replaceUrl = (replaceQueries = {}) => {
  const { origin, pathname, search } = window.location
  const queries = qs.parse(search.substring(1)) || {}
  for (const key in replaceQueries) {
    queries[key] = replaceQueries[key]
  }
  const newUrl = origin + pathname + '?' + qs.stringify(queries)
  window.history.replaceState({ path: newUrl }, '', newUrl)
}

export const hasQuery = (q) => {
  return Number(getQuery(q) || 0)
}
export const isPreload = () => {
  return hasQuery('is_gaoneng_preload')
}
export const isPreRender = () => {
  return +getQuery('pre_render_global') !== 0
}

export const getEnv = (env) => {
  const envDesc = {
    1: 'test',
    2: 'pre',
    3: 'uat',
    4: '' // prod 线上环境不展示
  }

  return envDesc[env] || ''
}

export const getProbableRes = (probabilities = []) => {
  if (probabilities.length === 0) return 0

  const random = Math.random(0, 1)

  const accumulateSums = probabilities.map((p, idx) => {
    return probabilities.slice(0, idx + 1).reduce((a, b) => a + b, 0)
  })

  for (let i = 0; i < accumulateSums.length; i++) {
    const sum = accumulateSums[i]
    const nextSum = accumulateSums[i + 1] || 1
    if (random <= sum) {
      return i
    }
    if (random > sum && random <= nextSum) {
      return i + 1
    }
  }
  return 0
}
