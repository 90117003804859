<template>
  <div
    :class="{ 'cc-video-empty': isEmpty }"
    :style="isEmpty ? emptyVideoStyle : {}"
    class="cc-video cc-video-fullscreen"
  >
    <div
      v-show="!isEmpty"
      ref="video"
      v-impr="reportInfo.id"
      :impr-data="JSON.stringify(reportInfo)"
      class="bilibiliPlayer cc-video"
    ></div>
    <Float
      v-show="floating.showTimeStatus"
      ref="float"
      :data="floating"
      :download="download"
      fullscreen
      @replay="seekVideo(0, true)"
      @video-continue="onVideoContinue"
      @video-hold="onVideoHold"
    ></Float>
  </div>
</template>
<script>
import videoMixin from '../mixins/video'

export default {
  name: 'CcVideoFullScreen',
  mixins: [videoMixin],
  computed: {
    playMode() {
      return this.context === 'editor' ? 3 : 1
    }
  }
}
</script>
