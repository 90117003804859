<template>
  <div :style="{ color: themeColor }" class="scroll-wall">
    <div :style="{ backgroundColor: themeColor }" class="bg"></div>
    <div class="title">最新参与用户</div>
    <swiper v-if="finalList && finalList.length" ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="(item, groupIdx) in finalList" :key="groupIdx" class="swiper-no-swiping">
        <div class="inline">
          <div :style="{ backgroundColor: themeColor }" class="inline-bg"></div>
          <div class="inline-content">
            <div class="name-area">
              <i class="icon iconfont icontongzhi"></i>
              <span>{{ item.name }}</span>
            </div>
            <div class="tel-area">{{ item.phone }}</div>
            <div class="desc-area">{{ desc === 'xx分钟前' ? item.time_desc : desc }}</div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import mixin from '../mixin'

export default {
  name: 'ScrollWallTheme',
  mixins: [mixin],
  props: {
    themeColor: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      finalList: [],
      swiperOptions: {
        autoplay: {
          delay: 1500
        },
        spaceBetween: 0,
        disableOnInteraction: false,
        loop: true,
        noSwiping: true,
        direction: 'vertical',
        speed: 1500,
        slidesPerView: 3
      }
    }
  }
}
</script>

<style lang="less" scoped>
.scroll-wall {
  position: relative;
  width: 100%;
  height: 180px;
  background: #f8f8f8;
  border-radius: 10px;
  padding: 14px 20px 0;
  margin-top: 18px;
  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;
    opacity: 0.08;
  }
  .title {
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 9px;
  }
}
.inline {
  width: 100%;
  height: 38px;
  .inline-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    left: 0;
    top: 0;
    opacity: 0;
  }
  .inline-content {
    width: 100%;
    height: 38px;
    display: flex;
    padding: 0 16px 0 14px;
    border-radius: 6px;
    align-items: center;
    opacity: 0.2;
    background-color: transparent;
  }
}
.name-area,
.tel-area,
.desc-area {
  display: inline-block;
  width: 33.333%;
  font-size: 13px;
  line-height: 1;
}
.name-area {
  display: flex;
  align-items: center;
  & > i {
    font-size: 18px;
    margin-right: 9px;
  }
  text-align: left;
}
.tel-area {
  text-align: center;
}
.desc-area {
  text-align: right;
}
.swiper-container {
  z-index: 0 !important;
  width: 100%;
  height: 120px;
}
.swiper-slide {
  width: 100% !important;
  height: 40px !important;
}
.swiper-slide-active + .swiper-slide {
  .inline-content {
    font-weight: bold;
    animation: active-content-animation 1.5s ease-out forwards;
  }
  .inline-bg {
    animation: active-bg-animation 1.5s ease-out forwards;
  }
  @keyframes active-content-animation {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes active-bg-animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.12;
    }
  }
}
.swiper-slide-active {
  .inline-content {
    animation: active-prev-content-animation 0.5s ease-out forwards;
  }
  .inline-bg {
    animation: active-prev-bg-animation 0.5s ease-out forwards;
  }
  @keyframes active-prev-content-animation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  @keyframes active-prev-bg-animation {
    0% {
      opacity: 0.12;
    }
    100% {
      opacity: 0;
    }
  }
}
</style>
