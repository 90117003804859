<template>
  <div class="rectangle" :style="styles">{{ instanceData.content }}</div>
</template>
<script>
export default {
  props: {
    component: {
      type: Object,
      default: null
    }
  },
  computed: {
    instanceData() {
      return this.component.data
    },
    styles() {
      return {
        'background-color': this.instanceData.bgColor
      }
    }
  }
}
</script>
<style scoped>
.rectangle {
  width: 100%;
  height: 100%;
}
</style>
