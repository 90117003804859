let popCount: number = 0
export const usePopCount = () => {
  return {
    getPopCount: () => popCount,
    addPopCount: () => {
      popCount += 1
    },
    reducePopCount: () => {
      popCount -= 1
    }
  }
}
