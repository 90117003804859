<template>
  <div>
    <ScrollWallTheme v-if="styleColor === 'theme'" :desc="desc" :list="list" :theme-color="themeColor" />
    <ScrollWallDefault v-if="styleColor === 'default'" :desc="desc" :list="list" :theme-color="themeColor" />
  </div>
</template>

<script>
import ScrollWallDefault from './ScrollWallDefault'
import ScrollWallTheme from './ScrollWallTheme'

export default {
  name: 'ScrollWall',
  components: { ScrollWallTheme, ScrollWallDefault },
  props: {
    themeColor: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    styleColor: {
      type: String,
      default: 'default'
    }
  }
}
</script>
