<template>
  <a :class="{ disabled: !href }" :download="link.type === 'downloadEvent'" :href="href" class="component-event-link">
    <div class="text-wrapper" @click="onClick">
      <slot name="before"></slot>
      <slot />
    </div>
    <float-form-panel :data="link" :show-modal="showFloatForm"></float-form-panel>
  </a>
</template>
<script>
import linkMixin from './linkMixin'
import FloatFormPanel from './form/FloatFormPanel'

export default {
  name: 'LinkComp',
  components: { FloatFormPanel },
  mixins: [linkMixin]
}
</script>
<style lang="less" scoped>
.component-event-link {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  &.disabled {
    pointer-events: none;
  }
  .text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
</style>
