export const showTimeStatusMap = {
  EMPTY: 0,
  PLAY: 1,
  END: 2,
  PLAY_AND_END: 3
}

export const floatTypMap = {
  FORM: 1,
  DOWNLOAD: 2,
  BUTTON: 3
}

export const playStyleTypeMap = {
  SMALL_BIG: 1,
  BIG: 2,
  IMAGE: 3
}

export const videoStatus = {
  INIT: 0,
  PLAY: 1,
  PAUSED: 2,
  ENDED: 3
}

export const floatLabels = {
  1: '播放过程中出现',
  2: '播放结束出现',
  3: '播放过程和播放结束出现'
}

export const placeholders = {
  'plain-video':
    'https://i0.hdslb.com/bfs/activity-plat/static/20210415/d72cd055e5397bb5587804649580b870/9I0E5Es5sR.png',
  'full-video':
    'https://i0.hdslb.com/bfs/activity-plat/static/20210415/d72cd055e5397bb5587804649580b870/A5Nzaem1XB.png',
  'top-landscape-video':
    'https://i0.hdslb.com/bfs/activity-plat/static/20210415/d72cd055e5397bb5587804649580b870/9I0E5Es5sR.png',
  'top-portrait-video':
    'https://i0.hdslb.com/bfs/activity-plat/static/20210415/d72cd055e5397bb5587804649580b870/ewajZSqrcy.png'
}

export const floatFormPlaceHolders = {
  'full-video-end-form':
    'https://i0.hdslb.com/bfs/activity-plat/static/20210416/d72cd055e5397bb5587804649580b870/PBbgnIfbGi.png',
  'fixed-video-end-form':
    'https://i0.hdslb.com/bfs/activity-plat/static/20210416/d72cd055e5397bb5587804649580b870/r1p7sXvIGP.png',
  'video-play-form':
    'https://i0.hdslb.com/bfs/activity-plat/static/20210416/d72cd055e5397bb5587804649580b870/vP4yqnW5Sa.png',
  'video-play-image':
    'https://i0.hdslb.com/bfs/activity-plat/static/20210416/d72cd055e5397bb5587804649580b870/x4UrPmfrPH.png'
}
