<template>
  <div class="goods-component">
    <template v-if="isSingle">
      <GoodsItemMain v-if="showMain" :item="mainItem" :options="options" />
      <GoodsItemSingle
        v-for="(item, index) in noMainList"
        :key="index"
        :item="item"
        :options="options"
        class="single-item"
      />
    </template>
    <template v-if="!isSingle">
      <GoodsItemMain v-if="showMain" :item="mainItem" :options="options" />
      <div v-for="(line, index) in doubleList" :key="index" class="double-wrapper">
        <GoodsItemDouble :item="line[0]" :options="options" class="double-item" />
        <GoodsItemDouble v-if="line[1]" :item="line[1]" :options="options" class="double-item" />
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash'
import GoodsItemSingle from './GoodsItemSingle'
import GoodsItemMain from './GoodsItemMain'
import GoodsItemDouble from './GoodsItemDouble'

export default {
  name: 'CcGoods',
  components: { GoodsItemDouble, GoodsItemMain, GoodsItemSingle },
  provide() {
    return {
      context: this.context,
      compName: this.component.name,
      compId: this.component.id
    }
  },
  props: {
    component: {
      type: Object,
      default: null
    },
    context: {
      type: String,
      default: ''
    },
    showMain: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      goodsList: [
        {
          is_main: true,
          name: '这里是填写商品名称及介绍内容两行显示，超过两行省略...',
          desc: '这里是填写商品描述及介绍内容两行显示，超过一行省略...',
          price: 999
        },
        {
          name: '这里是填写商品名称及介绍内容两行显示，超过两行省略...',
          desc: '这里是填写商品描述及介绍内容两行显示，超过一行省略...',
          price: 999
        },
        {
          name: '这里是填写商品名称及介绍内容两行显示，超过两行省略...',
          desc: '这里是填写商品描述及介绍内容两行显示，超过一行省略...',
          price: 999
        },
        {
          name: '这里是填写商品名称及介绍内容两行显示，超过两行省略...',
          desc: '这里是填写商品描述及介绍内容两行显示，超过一行省略...',
          price: 999
        },
        {
          name: '这里是填写商品名称及介绍内容两行显示，超过两行省略...',
          desc: '这里是填写商品描述及介绍内容两行显示，超过一行省略...',
          price: 999
        }
      ]
    }
  },
  computed: {
    data() {
      return this.component?.data || {}
    },
    content() {
      return this.data.content || {}
    },
    app() {
      return this.data.app || {}
    },
    listStyle() {
      return this.data?.listStyle
    },
    columnType() {
      return this.listStyle?.columnType
    },
    isSingle() {
      return this.columnType === 0
    },
    options() {
      return {
        ...this.listStyle,
        buttonText: this.content?.text,
        priorityDisplay: this.content?.priorityDisplay,
        app: this.app
      }
    },
    noMainList() {
      return this.goodsList.filter((item) => !item.is_main).slice(0, this.showMain ? 2 : 4)
    },
    doubleList() {
      return _.chunk(this.noMainList, 2)
    },
    mainItem() {
      return this.goodsList.find((item) => item.is_main)
    }
  }
}
</script>

<style lang="less" scoped>
.goods-component {
  position: relative;
  width: 100%;
  padding: 15px;
  &.single {
    padding-bottom: 14px;
  }
}
.single-item:not(:last-of-type) {
  margin-bottom: 19px;
}
.double-wrapper {
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
  .double-item {
    &:nth-of-type(1) {
      margin-right: 7.5px;
    }
    &:nth-of-type(2) {
      margin-left: 7.5px;
    }
  }
}
</style>
