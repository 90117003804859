<template>
  <div class="i-loading" :class="{ 'i-loading-fix': fix }">
    <img
      class="i-loading-img"
      src="https://i0.hdslb.com/bfs/activity-plat/static/20210416/d72cd055e5397bb5587804649580b870/OV3xRWIVjL.gif"
    />
  </div>
</template>
<script>
export default {
  name: 'ILoading',
  props: {
    fix: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped lang="less">
.i-loading {
  &.i-loading-fix {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  }

  .i-loading-img {
    width: 64px;
    height: 64px;
  }
}
</style>
