export const types = {
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  INPUT: 'input',
  TELEPHONE: 'telephone',
  SUBMIT: 'submit',
  ADDRESS: 'address',
  IDCARD: 'idcard',
  DROPDOWN: 'dropdown',
  LBS: 'lbs_dropdown',
  CUSTOMIZE_LBS_DROP_DOWN: 'customize_lbs_dropdown' // "自定义LBS下拉组件"
}

export const subTypes = {
  NAME: 1
}

// b站登录手机号授权弹窗是否弹过（48小时后端会重置）
export const allowLoginInfoToastEnum = {
  NOT_SHOW: 0,
  SHOW: 1
}

export const phoneChannelEnum = {
  USER_INPUT: 1,
  LOCAL_PHONE: 2, // 本机号码
  B_PHONE: 3 // b站手机号
}

// 是否勾选过使用历史信息
export const allowHistoryStatusEnum = {
  NOT_SET: 0,
  ALLOW: 1,
  NOT_ALLOW: 2
}

// b站手机号实验组
export const bPhoneExpEnum = {
  BASE: 0,
  EXP_AUTH: 1, // 授权弹窗
  EXP_CLICK: 2 // 一键填写
}
