export enum LBSTYPE {
  PRODUCT = 'product',
  PROVINCE = 'province',
  CITY = 'city',
  SHOP = 'shop'
}
export interface Children {
  type: string
  id: number
  name: string
  level: number
  w?: number
  address?: string
  children?: Children[]
}
export interface ILBS {
  id: number
  name: string
  default: string[]
  title: string
  fields: {
    type: string
    name: string
  }[]
  children?: Children[]
}
export interface Column {
  id: number
  name: string
  idx: number | undefined
}
export interface PickerInfo {
  level: number
  ref: null | HTMLFormElement
  field: {
    name: string
    type: string
  }
  columns: Column[]
  selected: {
    id: number
    idx: number | undefined
    name: string
  }
}
