<template>
  <div v-if="items.length === 2" class="image-two-display">
    <div v-for="(item, index) in items" :key="index" class="image-two-single">
      <CcImageV2 :image="item.image" :link="item.link" :report-info="{ img_num: index }" empty-img-width="41%" />
    </div>
  </div>
  <div v-else class="image-three-display">
    <div v-for="(item, index) in items.slice(0, 3)" :key="index" class="image-three-single">
      <CcImageV2 :image="item.image" :link="item.link" :report-info="{ img_num: index }" empty-img-width="45%" />
    </div>
  </div>
</template>
<script>
import CcImageV2 from '../components/ImageV2'

export default {
  name: 'ImageMulti',
  components: {
    CcImageV2
  },
  provide() {
    return {
      compName: this.component.name,
      compId: this.component.id
    }
  },
  props: {
    component: {
      type: Object,
      default: null
    }
  },
  computed: {
    data() {
      return this.component.data
    },
    items() {
      return this.data?.content.imageMulti || {}
    }
  }
}
</script>
<style lang="less" scoped>
.image-two-display {
  height: 100%;
  display: flex;
  margin: 0 12.5px;
  justify-content: space-between;
  align-content: center;
}

.image-two-single {
  margin: 0 2.5px;
  width: 170px;
  height: 100%;

  .cc-image {
    width: auto !important;
    height: auto !important;
  }
}

.image-three-display {
  height: 100%;
  display: flex;
  margin: 0 9px;
  justify-content: space-between;
  align-content: center;
}

.image-three-single {
  margin: 0 3px;
  width: 111px;
  height: 100%;
}
</style>
