<template>
  <div :style="outerStyle" class="image">
    <img :style="innerStyle" class="empty-image" src="../../../assets/image_empty.png" />
  </div>
</template>

<script>
export default {
  name: 'EmptyImage',
  props: {
    outerStyle: {
      type: Object,
      default: () => ({})
    },
    innerStyle: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="less" scoped>
.image {
  width: 100%;
  height: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f5f5f5;
  border-radius: 4px;
  .empty-image {
    width: 76px;
    height: 57px;
  }
}
</style>
