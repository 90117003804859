<template>
  <base-popup v-model="visible">
    <div class="wrapper">
      <div class="title">温馨提示</div>
      <div class="content">
        <div class="tips">即将开始下载</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <van-button @click="doNo">不，谢谢</van-button>
        <van-button type="info" @click="doYes">好的</van-button>
      </span>
    </div>
  </base-popup>
</template>

<script>
import {
  MINI_download_modal_fail,
  MINI_download_modal_show,
  MINI_download_modal_success
} from '../../constants/reportEvents'
import BasePopup from '../base/BasePopup'

export default {
  name: 'DownloadConfirmModal',
  components: { BasePopup },
  data() {
    return {
      visible: false
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.imprReport()
      }
    }
  },
  methods: {
    imprReport() {
      this.$reportUI(MINI_download_modal_show)
    },
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    doNo() {
      this.close()
      this.$reportUI(MINI_download_modal_fail)
    },
    doYes() {
      this.$emit('download')
      this.close()
      this.$reportUI(MINI_download_modal_success)
    }
  }
}
</script>

<style lang="less" scoped>
.van-popup {
  border-radius: 10px !important;
}
.van-button {
  width: 116px;
  height: 40px !important;
  line-height: 40px !important;
  border-radius: 4px !important;
  &:first-of-type {
    margin-right: 10px;
  }
  &.van-button--info {
    border-color: #0a8afa !important;
    background-color: #0a8afa !important;
  }
}
.wrapper {
  width: 280px;
  height: 164px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
}
.tips {
  color: #999;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 34px;
}
.agreement {
  display: block;
  font-size: 12px;
  line-height: 18px;
  color: #0a8afa;
}
</style>
