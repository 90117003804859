import 'intersection-observer'
import { MINI_item_show, MINI_video_show } from '../constants/reportEvents'
import { getFirstScreen } from '../utils'

const DURATION = 1000
const THRESHOLD = 0.001

export default (Vue) => {
  const options = {
    root: null, // 默认浏览器视窗
    threshold: THRESHOLD // 元素一定比例出现在浏览器视窗内才执行callback函数。
  }
  const timer = {} // 增加定时器对象
  const callback = (entries) => {
    entries.forEach((entry) => {
      let imprData = null
      try {
        imprData = JSON.parse(entry.target.getAttribute('impr-data'))
        imprData.first_screen = Number(getFirstScreen(entry.target))
        if (imprData.ignore_impr) {
          imprData = null
        }
      } catch (e) {
        imprData = null
        console.error('埋点数据格式异常', e)
      }
      // 没有埋点数据取消上报
      if (!imprData) {
        observer.unobserve(entry.target)
        return
      }

      if (entry.isIntersecting) {
        timer[imprData.id] = setTimeout(function () {
          // 上报埋点信息
          if (imprData.item_style === 'video') {
            delete imprData.item_style
            delete imprData.ignore_impr
            Vue.prototype.$reportUI(MINI_video_show, {
              ...imprData
            })
          } else {
            Vue.prototype.$reportUI(MINI_item_show, {
              ...imprData
            })
          }
          observer.unobserve(entry.target)
          visuallyList.push(imprData.id)
          timer[imprData.id] = null
        }, DURATION)
      } else {
        if (timer[imprData.id]) {
          clearTimeout(timer[imprData.id])
          timer[imprData.id] = null
        }
      }
    })
  }
  const observer = new IntersectionObserver(callback, options)
  const visuallyList = [] // 记录已经上报过的埋点信息
  const addListener = (ele, binding) => {
    if (visuallyList.indexOf(binding.value) !== -1) return

    observer.observe(ele)
  }
  const removeListener = (ele) => {
    observer.unobserve(ele)
  }
  // 自定义曝光指令
  Vue.directive('impr', {
    bind: addListener,
    unbind: removeListener
  })
}
