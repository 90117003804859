<template>
  <div ref="msg" :style="{ color: color }" class="cc-form-msg-speaker">
    <div class="inner-part">
      <div :style="{ backgroundColor: color, ...bgStyle }" class="bg"></div>
      <i class="icon iconfont icontongzhi"></i>
      <div>{{ countOptions.prefix }}</div>
      <div class="number">{{ countOptions.count }}</div>
      <div>{{ countOptions.suffix }}</div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'CountSpeaker',
  mixins: [mixin],
  props: {
    countOptions: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="less" scoped>
.cc-form-msg-speaker {
  position: relative;
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 2px;
  max-width: 100%;
  .inner-part {
    position: relative;
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    padding: 0 12px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      opacity: 0.06;
      left: 0;
    }
    i {
      margin-right: 8px;
    }
    .number {
      font-size: 18px;
      margin: 0 4px;
    }
  }
}
</style>
