<template>
  <div ref="content" class="cc-plain-text" :class="`text-font-${fontName}`" :style="style">
    {{ content.content || '请添加文本内容' }}
  </div>
</template>
<script>
export default {
  props: {
    component: {
      type: Object,
      default: null
    },
    context: {
      type: String,
      default: ''
    }
  },
  computed: {
    data() {
      return this.component.data
    },
    content() {
      return this.data?.content || {}
    },
    style() {
      const { fontSize, letterSpacing, lineHeight, fontName, fontFamily, ...rest } = this.data?.style || {}

      return {
        ...rest,
        fontSize: fontSize + 'px',
        letterSpacing: letterSpacing + 'px',
        lineHeight: lineHeight + 'px'
      }
    },
    fontName() {
      const { fontName } = this.data?.style || {}

      return fontName || 'normal'
    }
  },
  watch: {
    content() {
      this.context === 'editor' && setTimeout(() => this.refresh())
    }
  },
  methods: {
    refresh() {
      this.data.layout.height = this.$refs.content.offsetHeight
      this.$nextTick(() => this.$emit('block-auto-expand'))
    }
  }
}
</script>
<style lang="less" scoped>
.cc-plain-text {
  white-space: pre-wrap;
  word-break: break-all;

  &.text-font-normal {
    font-family: Helvetica, Helvetica Neue, sans-serif, Arial, Droid Sans, Heiti SC, Droidsansfallback, Pingfang SC,
      Microsoft Yahei;
  }
}
</style>
