<template>
  <div class="scroll-bar">
    <div :style="bgStyle" class="bg"></div>
    <swiper v-if="finalList && finalList.length" ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="(item, index) in finalList" :key="index" class="swiper-no-swiping">
        <div :style="inlineStyle" class="inline">
          <div class="name-area">
            <i :style="{ color: themeColor }" class="icon iconfont icontongzhi"></i>
            <span>{{ item.name }}</span>
          </div>
          <div class="tel-area">{{ item.phone }}</div>
          <div class="desc-area">{{ desc === 'xx分钟前' ? item.time_desc : desc }}</div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'ScrollBar',
  mixins: [mixin],
  props: {
    themeColor: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    styleColor: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      finalList: [],
      swiperOptions: {
        autoplay: {
          delay: 1500
        },
        spaceBetween: 0,
        speed: 1500,
        disableOnInteraction: false,
        loop: true,
        noSwiping: true,
        direction: 'vertical'
      }
    }
  },
  computed: {
    bgStyle() {
      if (this.styleColor === 'theme') {
        return {
          opacity: 0.08,
          backgroundColor: this.themeColor
        }
      }
      return {}
    },
    inlineStyle() {
      if (this.styleColor === 'theme') {
        return {
          color: this.themeColor
        }
      }
      return {}
    }
  }
}
</script>

<style lang="less" scoped>
.scroll-bar {
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  padding: 0 24px 0 23px;
  margin-top: 18px;
  .bg {
    position: absolute;
    background: #f8f8f8;
    width: 100%;
    left: 0;
    border-radius: 10px;
    height: 100%;
    opacity: 1;
  }
  .inline {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: #666666;
  }
  .name-area,
  .tel-area,
  .desc-area {
    display: inline-block;
    width: 33.333%;
    font-size: 13px;
    line-height: 1;
  }
  .name-area {
    display: flex;
    align-items: center;
    & > i {
      font-size: 18px;
      margin-right: 9px;
    }
    text-align: left;
  }
  .tel-area {
    text-align: center;
  }
  .desc-area {
    text-align: right;
  }
}
.swiper-container {
  width: 100%;
  height: 100%;
  z-index: 0 !important;
  overflow: hidden;
}
.swiper-wrapper {
  width: 100% !important;
  height: 100% !important;
}
.swiper-slide {
  height: 100% !important;
  width: 100% !important;
}
</style>
