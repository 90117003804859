<template>
  <div></div>
</template>
<script>
import { showTimeStatusMap, floatTypMap, playStyleTypeMap, floatFormPlaceHolders } from './constants'

import dialog from '../../plugin/dialog'
import ImageCard from '../Image'
import SmallCard from './SmallCard'
import BigCard from './BigCard'
import EndCard from './EndCard'
import Form from './Form'

export default {
  name: 'CcVideoFloat',
  inject: ['pageId', 'pageQuery', 'compName', 'compId'],
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    /**
     * 吸顶视频样式不同
     * **/
    size: {
      type: String,
      default: ''
    },
    download: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showTimeStatusMap,
      playStyleTypeMap,
      floatTypMap,

      isPlayingCardClose: false,

      cardType: '',
      cardModal: null,
      formModal: null,

      cardFormInfo: {},
      modalFormInfo: {},
      endFormInfo: {},

      buttonInfo: {
        text: '立即提交'
      }
    }
  },
  computed: {
    appInfo() {
      return this.data?.floatSetting || {}
    },
    buttonStyle() {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { borderWidth, borderRadius, backgroundImage, fontSize, ...rest } = this.data?.buttonStyle || {}

      return {
        borderRadius: borderRadius + 'px',
        borderWidth: borderWidth + 'px',
        fontSize: fontSize + 'px',
        ...rest
      }
    },
    buttonContent() {
      if (this.data.floatType === floatTypMap.FORM) {
        return this.buttonInfo
      }

      return {
        link: this.data.link,
        text: this.data?.floatSetting?.buttonText
      }
    },
    //
    form() {
      return this.data?.form || {}
    },
    floatSetting() {
      return this.data?.floatSetting || {}
    }
  },
  watch: {
    'form.id'() {
      this.refresh()
    },
    isPlayingCardClose(close) {
      close && this.clearFloat()
    }
  },
  mounted() {
    this.refresh()
  },
  beforeDestroy() {
    this.clearFloat(true)
  },
  methods: {
    showFormSmallCard() {
      this.cardModal = dialog.open({
        title: '',
        buttons: [],
        overlay: false,
        closeable: false,
        className: 'cc-video-float cc-float-small-card cc-float-readonly',
        el: this.$parent.$el,
        provide: {
          pageId: this.pageId,
          pageQuery: this.pageQuery,
          compName: this.compName,
          compId: this.compId
        },
        onClick: () => this.showFormModal(),
        onClose: () => {
          this.isPlayingCardClose = true
        },
        render: (h) =>
          h(SmallCard, {
            props: {
              app: this.appInfo,
              button: this.buttonInfo,
              buttonStyle: this.buttonStyle,
              download: this.download
            }
          })
      })
    },
    showFormBigCard() {
      this.formModal && this.formModal.destroy()
      this.cardModal = dialog.open({
        title: '',
        buttons: [],
        overlay: false,
        className: 'cc-video-float cc-float-big-card cc-float-form-card cc-float-readonly',
        el: this.$parent.$el,
        provide: {
          pageId: this.pageId,
          pageQuery: this.pageQuery,
          compName: this.compName,
          compId: this.compId
        },
        onClick: () => this.showFormModal(),
        onClose: () => {
          this.isPlayingCardClose = true
        },
        onOk: () => {
          this.isPlayingCardClose = true
        },
        render: (h) =>
          h(Form, {
            props: {
              data: this.cardFormInfo,
              buttonStyle: this.buttonStyle,
              showPrefix: false,
              showAutoFill: false,
              empty: true,
              placeholder: floatFormPlaceHolders['video-play-form']
            },
            scopedSlots: {
              title: () =>
                h(
                  'div',
                  {
                    class: 'cc-float-form-title ellipsis'
                  },
                  this.appInfo.title || '表单'
                )
            }
          })
      })
    },
    // 暂停视频，关闭后恢复视频状态
    showFormModal() {
      this.$emit('video-hold')
      this.formModal && this.formModal.destroy()
      this.formModal = dialog.open({
        title: '',
        buttons: [],
        overlay: true,
        overlayClass: 'cc-video-float-overlay',
        className: 'cc-video-float cc-float-big-card cc-float-form-card cc-float-form-body',
        el: this.$parent.$el,
        provide: {
          pageId: this.pageId,
          pageQuery: this.pageQuery,
          compName: this.compName,
          compId: this.compId
        },
        onOk: () => {
          this.$emit('video-continue')
          this.isPlayingCardClose = true
        },
        onClose: () => {
          this.$emit('video-continue')
        },
        render: (h) =>
          h(Form, {
            props: {
              data: this.modalFormInfo,
              buttonStyle: this.buttonStyle,
              showPrefix: false,
              showAutoFill: false,
              cardType: this.cardType
            },
            scopedSlots: {
              title: () =>
                h(
                  'div',
                  {
                    class: 'cc-float-form-title ellipsis'
                  },
                  this.appInfo.title || '表单'
                )
            }
          })
      })
    },
    showImageCard() {
      const image = this.data?.floatSetting?.imageUrl || floatFormPlaceHolders['video-play-image']
      const link = this.data?.link

      this.cardModal = dialog.open({
        title: '',
        buttons: [],
        overlay: false,
        className: 'cc-video-float cc-float-big-card cc-float-image-card',
        el: this.$parent.$el,
        provide: {
          pageId: this.pageId,
          pageQuery: this.pageQuery,
          compName: this.compName,
          compId: this.compId
        },
        onClose: () => {
          this.isPlayingCardClose = true
        },
        render: (h) =>
          h(ImageCard, {
            props: {
              image,
              link
            }
          })
      })
    },
    showBigCard() {
      if (this.isPlayingCardClose) {
        return
      }

      if (this.data.floatType === floatTypMap.FORM) {
        this.cardType = 'form'
        this.showFormBigCard()
        return
      }

      if (this.data.playStyleType === playStyleTypeMap.IMAGE) {
        this.cardType = 'image'
        this.showImageCard()
        return
      }

      this.cardType = 'big'
      this.cardModal = dialog.open({
        title: '',
        buttons: [],
        overlay: false,
        className: 'cc-video-float cc-float-big-card',
        el: this.$parent.$el,
        provide: {
          pageId: this.pageId,
          pageQuery: this.pageQuery,
          compName: this.compName,
          compId: this.compId
        },
        onClose: () => {
          this.isPlayingCardClose = true
        },
        render: (h) =>
          h(BigCard, {
            props: {
              app: this.appInfo,
              button: this.buttonContent,
              buttonStyle: this.buttonStyle,
              download: this.download
            }
          })
      })
    },
    showSmallCard() {
      if (this.isPlayingCardClose) {
        return
      }

      this.cardType = 'small'
      if (this.data.floatType === floatTypMap.FORM) {
        return this.showFormSmallCard()
      }

      this.cardModal = dialog.open({
        title: '',
        buttons: [],
        overlay: false,
        closeable: false,
        className: 'cc-video-float cc-float-small-card',
        el: this.$parent.$el,
        provide: {
          pageId: this.pageId,
          pageQuery: this.pageQuery,
          compName: this.compName,
          compId: this.compId
        },
        render: (h) =>
          h(SmallCard, {
            props: {
              app: this.appInfo,
              button: this.buttonContent,
              buttonStyle: this.buttonStyle,
              download: this.download
            }
          })
      })
    },
    showEndCard() {
      const isForm = this.data.floatType === floatTypMap.FORM

      const renderForm = (h) =>
        isForm
          ? h(Form, {
              provide: {
                pageId: this.pageId,
                pageQuery: this.pageQuery,
                compName: this.compName,
                compId: this.compId
              },
              props: {
                buttonStyle: this.buttonStyle,
                data: this.endFormInfo,
                showPrefix: this.size !== 'small',
                showAutoFill: this.size !== 'small',
                placeholder:
                  this.size === 'small'
                    ? floatFormPlaceHolders['fixed-video-end-form']
                    : floatFormPlaceHolders['full-video-end-form'],
                cardType: this.cardType
              },
              on: {
                success: () => {},
                error: () => {}
              }
            })
          : undefined

      this.cardType = 'end'
      this.cardModal = dialog.open({
        title: '',
        buttons: [],
        overlay: false,
        // overlayClass: 'cc-video-float-overlay',
        className: `cc-float-end-card-wrap`,
        el: this.$parent.$el,
        closeable: false,
        closeOnClickOverlay: false,
        provide: {
          pageId: this.pageId,
          pageQuery: this.pageQuery,
          compName: this.compName,
          compId: this.compId
        },
        on: {
          replay: () => this.onReplay()
        },
        render: (h) =>
          h(EndCard, {
            props: {
              app: this.appInfo,
              button: this.buttonContent,
              buttonStyle: this.buttonStyle,
              size: this.size,
              download: this.download
            },
            scopedSlots: {
              app: () => (isForm && this.size === 'small' && h('div')) || undefined,
              form: () => renderForm(h),
              button: () =>
                h(
                  'van-button',
                  {
                    class: 'replay-btn',
                    props: {
                      plain: true,
                      icon: 'https://i0.hdslb.com/bfs/activity-plat/static/20210421/d72cd055e5397bb5587804649580b870/ZWgdi6yiqf.svg',
                      tag: 'a'
                    },
                    on: {
                      click: () => this.onReplay()
                    }
                  },
                  '重播'
                )
            }
          })
      })
    },
    // 判断展示具体浮层
    showFloat(time, end) {
      const { floatType, playStyleType, showTimeStatus, start } = this.data || {}
      const bigCardType =
        floatType === floatTypMap.FORM ? 'form' : playStyleType === playStyleTypeMap.IMAGE ? 'image' : 'big'
      const cardType =
        end && showTimeStatus !== showTimeStatusMap.PLAY
          ? 'end'
          : playStyleType === playStyleTypeMap.SMALL_BIG && time < 5
          ? 'small'
          : bigCardType

      if (
        !showTimeStatus ||
        (end && showTimeStatus === showTimeStatusMap.PLAY) ||
        (!end && showTimeStatus === showTimeStatusMap.END) ||
        time < start
      ) {
        return this.clearFloat()
      }

      if (cardType === this.cardType && this.cardModal) {
        return
      }

      this.clearFloat()

      if (end) {
        return this.showEndCard()
      }

      return cardType === 'small' ? this.showSmallCard() : this.showBigCard()
    },
    clearFloat(all = false) {
      this.cardType = ''

      if (this.cardModal) {
        this.cardModal.destroy()
        this.cardModal = null
      }

      if (all && this.formModal) {
        this.formModal.destroy()
        this.formModal = null
      }
    },
    //
    onReplay() {
      this.clearFloat()
      this.$emit('replay')
    },
    queryForm(formId) {
      if (!formId) {
        return Promise.reject(new Error(''))
      }

      return this.$request.get(`v1/form/${formId}`).then((res) => {
        return res.data
      })
    },
    updateForm(form = {}) {
      let nameItem, telItem, buttonItem
      ;(form.items || []).forEach((item) => {
        switch (item.type) {
          case 'telephone':
            telItem = item
            break
          case 'submit':
            buttonItem = item
            break
          default:
            nameItem = item
        }
      })

      form.successMessage = this.data?.form?.successMessage || ''

      const formInfo = {
        form,
        items: [nameItem, telItem],
        button: buttonItem
      }

      this.cardFormInfo = JSON.parse(JSON.stringify(formInfo))
      this.modalFormInfo = JSON.parse(JSON.stringify(formInfo))
      this.endFormInfo = JSON.parse(JSON.stringify(formInfo))

      this.buttonInfo = { text: buttonItem?.label || '立即提交' }
    },
    refresh() {
      const formId = this.data?.form?.id

      this.formError = false
      this.queryForm(formId)
        .then((form) => {
          this.updateForm(form)
        })
        .catch(() => {
          this.formError = true
          this.updateForm({})
        })
    }
  }
}
</script>
