export const WARN_page_load_fail = 'WARN_page_load_fail'
// 手机号解析失败
export const WARN_page_form_phone_fail = 'WARN_page_form_phone_fail'
// 表单提交失败
export const WARN_page_form_submit_fail = 'WARN_page_form_submit_fail'
// 获取企业微信id
export const WARN_page_weixin_tackid_fail = 'WARN_page_weixin_tackid_fail'
// 获取表单数据失败
export const WARN_page_get_form_data_fail = 'WARN_page_get_form_data_fail'
// 获取本机号码失败
export const WARN_page_get_phone_number_fail = 'WARN_page_get_phone_number_fail'
// 获取短信验证码失败
export const WARN_page_get_phone_code_fail = 'WARN_page_get_phone_code_fail'
// 下载失败
export const WARN_page_download_fail = 'WARN_page_download_fail'
// 微信小程序跳转失败
export const WARN_page_weixin_game_fail = 'WARN_page_weixin_game_fail'
// 小程序跳转识别未安装微信
export const WARN_callup_weixin_not_install = 'WARN_callup_weixin_not_install'
