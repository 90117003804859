<template>
  <div class="weixin-button-small">
    <img src="../../assets/wechat_logo.svg" alt="" class="logo" @click="onBtnClick" />
    <wx-modal v-if="!isEditor" ref="modal" :data="content.modal" :content="content"></wx-modal>
  </div>
</template>

<script>
import mixin from '../weixin/mixin'
import WxModal from '../components/weixin/WxModal'

export default {
  components: {
    WxModal
  },
  mixins: [mixin],
  methods: {
    onBtnClick(ev) {
      if (this.content?.weixin?.type === 100) {
        this.linkGet()
        this.reportClickLink(ev)
      } else {
        this.$refs.modal.open()
        this.reportClick(ev)
      }
    }
  }
}
</script>
<style scoped lang="less">
.weixin-button-small {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .logo {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    cursor: pointer;
  }
}
</style>
