<template>
  <div>
    <div class="popup-header">
      <div class="close-icon" @click="close">
        <img src="//i0.hdslb.com/bfs/activity-plat/static/20240409/4fd3833722492cf2d9e284c3722f6329/qY29dSHfFI.svg">
      </div>
      <slot></slot>
    </div>
    <div class="header-line"></div>
  </div>
</template>
<script>
export default {
  name: "PopupHeader",
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
<style scoped lang="less">
  .popup-header {
    width: 100%;
    height: 52px;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .close-icon {
    position: absolute;
    width: 28px;
    height: 28px;
    top: 12px;
    left: 12px;
  }
  .close-icon img {
    width: 100%;
    height: 100%;
  }
  .header-line {
    height: 1px;
    width: 100%;
    background: rgba(227, 229, 231, 1);
    transform: scaleY(50%);
  }
</style>
