<template>
  <div class="goods-item-main">
    <EmptyImage :inner-style="{ width: '86px', height: '65px' }" :outer-style="{ height: '200px' }" />
    <div class="name">{{ item.name }}</div>
    <div v-if="showPrice" :style="priceStyle" class="price">
      <span>￥</span>
      <span>{{ item.price }}</span>
    </div>
    <div v-else-if="showDesc" class="desc">{{ item.desc }}</div>
    <LinkComp v-if="showButton" :link="link">
      <div :style="buttonStyle" class="download-button">{{ buttonStyle.text }}</div>
    </LinkComp>
  </div>
</template>

<script>
import mixin from './mixin'
import LinkComp from '../Link'
import EmptyImage from './EmptyImage'

export default {
  name: 'GoodsItemMain',
  components: { EmptyImage, LinkComp },
  mixins: [mixin]
}
</script>

<style lang="less" scoped>
.goods-item-main {
  position: relative;
  width: 345px;
  margin: 0 auto;
  padding-bottom: 20px;
  .name {
    width: 100%;
    margin-top: 10px;
    max-height: 44px;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .price {
    margin-top: 10px;
    line-height: 1;
    color: #ff6699;
    font-weight: 600;
    height: 28px;
    display: flex;
    align-items: center;
    span:first-of-type {
      font-size: 14px;
    }
    span:last-of-type {
      font-size: 20px;
    }
  }
  .desc {
    margin-top: 10px;
    color: #999999;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .download-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    margin-bottom: 10px;
    border: 0.5px solid #ff6699;
    box-sizing: border-box;
    border-radius: 4px;
    color: #ff6699;
    height: 32px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
  }
}
</style>
