<template>
  <div>
    <base-popup v-model="visible">
      <div class="wrapper">
        <div class="title">温馨提示</div>
        <div class="content">
          <div class="tips">保存手机号，下次填写更便捷~</div>
          <a class="agreement" @click="$refs.modal.open()">《授权书》</a>
        </div>
        <span class="dialog-footer">
          <van-button @click="postChoice(false)">不，谢谢</van-button>
          <van-button type="info" @click="postChoice(true)">好的</van-button>
        </span>
      </div>
    </base-popup>
    <AgreementAuth ref="modal"></AgreementAuth>
  </div>
</template>

<script>
import AgreementAuth from './AgreementLocation'
import { serializePhoneExt } from '../utils'
import BasePopup from '../../base/BasePopup'

export default {
  name: 'AgreementConfirm',
  components: { AgreementAuth, BasePopup },
  props: {
    showModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false
    }
  },
  watch: {
    visible() {
      this.postShowToast()
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    postShowToast() {
      this.postPhoneData({ allow_history_toast: 1 })
    },
    postChoice(yes) {
      if (!yes) {
        this.close()
        this.$emit('submit')
        return
      }
      this.postPhoneData({ allow_history: yes ? 1 : 2 }).then(() => {
        this.close()
        this.$emit('submit')
      })
    },
    postPhoneData(params = {}) {
      return this.$request.put(
        'v1/form_data/phone',
        serializePhoneExt({
          user_info: { ...params }
        })
      )
    }
  }
}
</script>

<style lang="less" scoped>
.van-popup {
  border-radius: 10px !important;
}
.wrapper {
  padding: 20px 0;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.content {
  margin-bottom: 30px;
}
.title {
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
}
.tips {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}
.agreement {
  display: block;
  font-size: 12px;
  line-height: 18px;
  color: #0a8afa;
}
.van-button {
  width: 123px;
  height: 44px;
  border-radius: 6px !important;
  font-weight: normal;
  &:first-of-type {
    margin-right: 10px;
  }
  &.van-button--info {
    border-color: #0a8afa !important;
    background-color: #0a8afa !important;
  }
}
</style>
