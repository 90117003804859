<template>
  <div :class="{ 'cc-video-empty': isEmpty }" :style="isEmpty ? emptyVideoStyle : {}" class="cc-video cc-video-fixed">
    <div
      v-show="!isEmpty"
      id="bilibiliPlayer"
      ref="video"
      v-impr="reportInfo.id"
      :class="{ mask: isEditor && !floating.showTimeStatus }"
      :impr-data="JSON.stringify(reportInfo)"
      class="bilibiliPlayer cc-video"
    ></div>
    <Float
      v-if="floating.showTimeStatus"
      ref="float"
      :data="floating"
      :download="download"
      fullscreen
      size="small"
      @replay="seekVideo(0, true)"
      @video-continue="onVideoContinue"
      @video-hold="onVideoHold"
    ></Float>
  </div>
</template>
<script>
import videoMixin from '../mixins/video'

export default {
  name: 'CcVideoFixed',
  mixins: [videoMixin],
  computed: {
    isEditor() {
      return this.context === 'editor'
    },
    playMode() {
      return this.isEditor ? 3 : 1
    }
  }
}
</script>
<style lang="less" scoped>
.mask:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}
</style>
