<template>
  <base-popup v-model="show" closeable @close="close">
    <div class="wrapper">
      <div class="title">温馨提示</div>
      <div class="tips">
        我们已向 <span class="page-wrap-phone">{{ currentPhone }}</span> 发送验证码短信
      </div>
      <div class="tips">查看短信并输入验证码</div>
      <div class="v-code">
        <input
          id="code"
          ref="code"
          v-model="code"
          :disabled="telDisabled"
          :maxlength="6"
          class="code"
          type="tel"
          @blur="focus = false"
          @focus="focus = true"
        />
        <div class="labels">
          <label
            v-for="(item, index) in length"
            :key="index"
            :class="{ active: focus === true && index === currentIndex }"
            class="label"
            for="code"
            v-text="arrCode[index]"
          >
          </label>
        </div>
      </div>
      <div class="bottom-code-verify">
        <div v-show="sendCodeDisabled && sendCodeCount" class="code-verify-send">{{ sendCodeValue }}</div>
        <div v-if="sendCodeCount && !sendCodeDisabled" class="code-verify-send rec" @click="sendPhoneCode">
          {{ sendCodeValue }}
        </div>
      </div>
    </div>
  </base-popup>
</template>

<script>
import { serializeTelCode } from './utils'
import BasePopup from '../base/BasePopup'
import { phoneChannelEnum } from './constants'
import {
  MINI_tel_code_fail,
  MINI_tel_code_success,
  MINI_tel_code_verification_fail,
  MINI_tel_code_verification_success
} from '@/canvas/constants/reportEvents'

export default {
  name: 'PhoneVerify',
  components: { BasePopup },
  props: {
    telNumber: {
      type: String || Number,
      default: ''
    },
    phoneChannel: {
      type: Number,
      default: phoneChannelEnum.USER_INPUT
    }
  },
  data() {
    return {
      length: 6,
      code: '',
      focus: true,
      telDisabled: false,
      sendCodeValue: '重新发送短信',
      // 展示发送验证码提示状态
      sendCodeDisabled: false,
      sendCodeCount: 0,
      count: 0,
      timer: null,
      show: false,
      telCodeExtra: {}
    }
  },
  computed: {
    arrCode() {
      return this.code.split('')
    },
    currentIndex() {
      return this.code.length
    },
    currentPhone() {
      return this.telNumber.toString().replace(this.telNumber.substring(3, 9), '******')
    }
  },
  watch: {
    code: function (val) {
      this.code = val.replace(/[^\d]/g, '') // 限制非数字
      if (val.length >= 6) {
        this.sendValidateCodeFunc(val)
      }
    },
    show(val) {
      if (val) {
        if (this.telNumber) {
          this.sendPhoneCode()
        }
      }
    },
    phoneChannel(val) {
      if (val === phoneChannelEnum.B_PHONE) {
        this.telCodeExtra = {
          phone_channel: this.phoneChannel,
          mid: window?.alita?.appInfo?.mid
        }
      } else {
        this.telCodeExtra.phone_channel = this.phoneChannel
      }
    }
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.reset()
      this.show = false
    },
    sendPhoneCode() {
      if (this.sendCodeDisabled) {
        this.$toast('验证码已发送，请注意查收')
        return
      }
      this.sendPhoneCodeCallback()
    },
    sendPhoneCodeCallback() {
      this.sendCodeValue = '发送中...'
      this.sendCodeDisabled = true
      this.sendCodeCount++

      this.$request
        .post('v1/form/ttcode', serializeTelCode(this.telNumber.trim(), null, this.telCodeExtra))
        .then(({ code, message }) => {
          this.sendCodeValue = '重新发送验证码'
          this.sendCodeDisabled = false
          if (code === 0) {
            this.start(60)
            this.$toast('验证码发送成功')
            this.$reportUI(MINI_tel_code_success)
            return
          }

          return Promise.reject(message)
        })
        .catch((message) => {
          this.start(0)
          const ERR_MSG = '获取验证码失败，请稍后再试'
          this.$toast(typeof message === 'string' ? message || ERR_MSG : ERR_MSG)
          this.$reportUI(MINI_tel_code_fail)
        })
    },
    sendValidateCodeFunc(newVal) {
      if (this.telDisabled) return
      this.telDisabled = true
      this.$refs.code.blur()

      this.$request
        .post('v1/form/ttcode/validate', serializeTelCode(this.telNumber.trim(), newVal, this.telCodeExtra))
        .then(({ code, message }) => {
          if (code === 0) {
            this.close()
            this.$emit('success', newVal)
            this.$reportUI(MINI_tel_code_verification_success)
            return
          }

          return Promise.reject(message)
        })
        .catch((message) => {
          this.telDisabled = false
          const ERR_MSG = '验证码校验失败，请重新尝试'
          this.$toast(typeof message === 'string' ? message || ERR_MSG : ERR_MSG)
          this.$reportUI(MINI_tel_code_verification_fail)
        })
    },
    start(ts = 60) {
      this.count = ts
      this.timer && clearTimeout(this.timer)
      this.calculate()
    },
    calculate() {
      if (this.count <= 0) {
        this.timer && clearTimeout(this.timer)
        this.timer = null
        this.count = 0
        this.sendCodeDisabled = false
        this.sendCodeValue = '重新发送验证码'
        return
      }
      this.count = this.count - 1
      this.sendCodeDisabled = true
      this.sendCodeValue = `${this.count}秒后可重新发送`

      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(this.calculate, 1000)
    },
    reset() {
      this.code = ''
      this.telDisabled = ''
      this.sendCodeValue = '重新发送短信'
      this.telDisabled = false
      this.sendCodeDisabled = false
      this.sendCodeCount = 0
      this.count = 0
      this.timer && clearTimeout(this.timer)
      this.timer = null
    }
  }
}
</script>

<style lang="less" scoped>
.van-popup {
  border-radius: 10px !important;
  width: 287px;
  height: 219px;
  padding-top: 20px;
  /deep/ .van-popup__close-icon {
    font-size: 18px !important;
    color: #666666 !important;
  }
}
.wrapper {
  text-align: center;
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #313332;
    margin-bottom: 15px;
  }
}
.tips {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  color: #666666;
}
@keyframes coruscate {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.v-code {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 12px;
}
.code {
  position: absolute;
  width: 100%;
  padding: 0;
  height: 40px;
  font-size: 35px;
  overflow: hidden;
  border: none;
  outline: none;
  opacity: 0;
  margin-left: -50%; // ios上透明度为0时依然有光标
  -webkit-tap-highlight-color: transparent;
}
.labels {
  margin: 0 auto;
  width: 210px;
  display: flex;
  display: -webkit-flex;
  height: 40px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -webkit-tap-highlight-color: transparent; // 解决ios点击灰色阴影的问题
}
.label {
  height: 34px;
  width: 32px;
  border-bottom: solid 1px rgba(255, 102, 153, 1);
  float: left;
  color: black;
  font-size: 18px;
  line-height: 34px;
  text-align: center;
}
.active:after {
  // 伪类实现光标效果
  content: '';
  display: inline-block;
  height: 50%;
  width: 1px;
  top: 20%;
  position: absolute;
  background-color: rgba(255, 102, 153, 1);
  animation: 1s coruscate infinite both;
}
.bottom-code-verify {
  overflow: hidden;
  width: 100%;
  left: 0;
  margin-top: 44px;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  text-align: center;
}
.code-verify-send {
  color: #999999;
}
.code-verify-send.rec {
  color: rgba(255, 102, 153, 1);
}
</style>
